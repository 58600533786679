import React, {
  useRef,
  useCallback,
  useState,
  useEffect,
  useContext,
  memo,
} from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../css/AnotherComponentVer2.css";
import { FontContext } from "../contexts/FontContext";
import { useLayout } from "../contexts/LayoutContext";
import { useTable } from "../contexts/TableContext";
import eventBus from "../utils/eventBus";
import { doc, getDoc, onSnapshot, setDoc } from "firebase/firestore";
import { db } from "../firebase/firebase";
import debounce from "lodash/debounce"; // 더 가벼운 import
const createEditorKey = (subunitId, rowIndex, colId, editorIndex = 0) => {
  return `${subunitId}-${rowIndex}-${colId}-${editorIndex}`;
};

const AnotherComponentVer2 = ({
  onEditorClick,
  tableStyle,
  currentFont,
  tableData,
  onTableDataChange,
  courseId,
  subunitId,
  onLoadComplete,
  onToggleRedText, // props로 추가
}) => {
  const { layout } = useLayout();
  const {
    saveTableData,
    loadTableData,
    setCurrentIds,
    updateWidths,
    updateEditorDivider,
    updateColumnWidths,
    updateEditorData,
    swapRows,
    insertEmptyRow,
    isLoading: authLoading,
    currentUser,
    saveAllTableData,
  } = useTable();
  // 컴포넌트 최상단 state 선언부에 추가
  const [editorReady, setEditorReady] = useState({});

  const [draggedCell, setDraggedCell] = useState(null);
  const [dragOverCell, setDragOverCell] = useState(null);
  const [isSelectMode, setIsSelectMode] = useState(false);
  const [selectedReviewEditors, setSelectedReviewEditors] = useState([]);

  const [initializedEditors, setInitializedEditors] = useState(new Set());
  const [isSelecting, setIsSelecting] = useState(false);
  const quillRefs = useRef({});
  const [editorContents, setEditorContents] = useState({});
  const [selectedCells, setSelectedCells] = useState(new Set());
  const [selectionStart, setSelectionStart] = useState(null);
  const [activeCell, setActiveCell] = useState(null);
  const [showMiniToolbar, setShowMiniToolbar] = useState(false);
  const [miniToolbarPosition, setMiniToolbarPosition] = useState({
    x: 0,
    y: 0,
  });
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [showBgColorPicker, setShowBgColorPicker] = useState(false);
  const { font } = useContext(FontContext);

  const [columnWidths, setColumnWidths] = useState(() => {
    const savedData = tableData?.columnWidths;
    return savedData || { col1: 20, col2: 80 }; // 기본값
  });
  const [isDragging, setIsDragging] = useState(false);
  const dragStartX = useRef(0);
  const dragStartWidths = useRef({});
  const toolbarRef = useRef(null);
  const [isRemovingSplit, setIsRemovingSplit] = useState(false);
  const [editors, setEditors] = useState({});
  const [isDraggingDivider, setIsDraggingDivider] = useState(false);
  const currentDraggingCell = useRef(null);
  const cellRef = useRef({});
  const [activeEditorIndex, setActiveEditorIndex] = useState(0);
  const [showRedText, setShowRedText] = useState(false);
  const [originalColors, setOriginalColors] = useState({});
  const tableRef = useRef(null);
  const [hiddenEditors, setHiddenEditors] = useState({
    col1: new Set(),
    col2: new Set(),
  });
  const [visibleEditors, setVisibleEditors] = useState({
    col1: new Set(),
    col2: new Set(),
  });
  const [isLoading, setIsLoading] = useState(true);
  const textColors = [
    "#F24722", // 빨간색
    "#FFA629", // 주황색
    "#FFCD29", // 노란색
    "#15AE5C", // 초록색
    "#0D99FF", // 파란색
    "#9747FF", // 보라색
    "#000000", // 검은색
    "#FFFFFF", // 흰색
  ];

  const highlightColors = [
    "#FFFCDB",
    "#FDEEDB",
    "#FEEFF6",
    "#F6ECF4",
    "#E9F6FE",
    "#EBF5EC",
    "#F4E9E3",
    "#EDE1D6",
    "#CDC3B9",
    "#C6CFDE",
    "#DCE7EA",
    "#E7E6EB",
    "#FAF0E8",
    "#F0DDD8",
    "remove",
  ];

  // 기본 모듈 설정 복원
  const modules = {
    toolbar: false,
    keyboard: {
      bindings: {
        tab: false,
      },
    },
    clipboard: {
      matchVisual: false,
    },
  };

  // 허용할 포맷들을 별도로 지정
  const formats = ["bold", "italic", "color", "background", "align"];
  // 스타일 적용 함수를 캘린더 방식으로 수정
  const applyStyle = useCallback(
    (style, value) => {
      if (activeCell) {
        const editor = quillRefs.current[activeCell]?.getEditor();

        if (editor) {
          const selection = editor.getSelection();
          if (selection) {
            switch (style) {
              case "bold":
                editor.format("bold", !editor.getFormat(selection).bold);
                break;
              case "italic":
                editor.format("italic", !editor.getFormat(selection).italic);
                break;
              case "color":
                editor.format("color", value);
                break;
              case "background":
                editor.format("background", value === "remove" ? false : value);
                break;
              case "align":
                editor.format("align", value === "left" ? null : value);
                break;
            }
          }
        }
      }
    },
    [activeCell]
  );

  const handleTextSelection = useCallback(() => {
    const selection = window.getSelection();
    if (!selection || selection.isCollapsed) {
      setShowMiniToolbar(false);
      return;
    }
    const selectedText = selection.toString();

    if (selectedText.length > 0) {
      const range = selection.getRangeAt(0);
      const rect = range.getBoundingClientRect();
      const tableRect = tableRef.current?.getBoundingClientRect() || {
        left: 0,
        top: 0,
      };
      const contentElement = document.querySelector(".content");
      const scale = parseFloat(
        contentElement?.style.transform.match(/scale\((.*?)\)/)?.[1] || 1
      );

      // 툴바의 예상 너비
      const toolbarWidth = 400;

      // 기본 위치 계산 (테이블 기준 상대 위치)
      let toolbarX = (rect.left - tableRect.left) / scale;
      let toolbarY = (rect.top - tableRect.top) / scale - 40;

      // 오른쪽 경계 체크
      if (toolbarX + toolbarWidth > tableRect.width) {
        toolbarX = tableRect.width - toolbarWidth - 10;
      }

      // 왼쪽 경계 체크
      if (toolbarX < 0) {
        toolbarX = 10;
      }

      // 상단 경계 체크
      if (toolbarY < 0) {
        toolbarY = (rect.bottom - tableRect.top) / scale + 10;
      }

      const quillElement =
        range.startContainer.parentElement.closest(".ql-editor");
      if (quillElement) {
        const tdElement = quillElement.closest("td");
        const baseEditorId = tdElement.dataset.editorId;

        const matches = baseEditorId.match(/^(.+)-(\d+)-(.+)-(\d+)$/);
        if (matches) {
          const [_, subunitId, rowIndex, colId, currentEditorIndex] = matches;

          const editorContainer = quillElement.closest("[data-editor-index]");
          const editorIndex = editorContainer
            ? parseInt(editorContainer.dataset.editorIndex)
            : parseInt(currentEditorIndex);

          const fullEditorKey = createEditorKey(
            subunitId,
            rowIndex,
            colId,
            editorIndex
          );

          setActiveCell(fullEditorKey);
          setActiveEditorIndex(editorIndex);

          setShowMiniToolbar(true);
          setMiniToolbarPosition({
            x: toolbarX,
            y: toolbarY,
          });
        }
      }
    } else {
      if (!selection.isCollapsed) {
        setShowMiniToolbar(false);
      }
    }
  }, []);

  // useEffect 추가
  useEffect(() => {
    let timeoutId;

    const handleMouseUp = (e) => {
      // 클릭된 요소가 에디터 내부인지 확인
      if (e.target.closest(".another-quill-editor")) {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
        timeoutId = setTimeout(() => {
          handleTextSelection();
        }, 0);
      }
    };

    // 테이블 컨테이너에 한 번만 이벤트 리스너 추가
    const container = document.querySelector(".another-table-wrapper");
    if (container) {
      container.addEventListener("mouseup", handleMouseUp);
    }

    return () => {
      if (container) {
        container.removeEventListener("mouseup", handleMouseUp);
      }
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [handleTextSelection]);

  // 디바운스 타이머를 컴포넌트 레벨에서 관리
  const updateTimerRef = useRef(null);
  const isTypingRef = useRef(false);
  const initialLoadDoneRef = useRef(false);
  const lastLoadedSubunitRef = useRef(null);
  const loadCompleteRef = useRef(false);
  const wasLoadedRef = useRef(false); // 이 서브유닛이 성공적으로 로드된 적이 있는지 추적
  const [isTableLoading, setIsTableLoading] = useState(true); // 로딩 상태 추가

  // 컴포넌트 언마운트 시 타이머 정리를 위한 useEffect 추가
  useEffect(() => {
    // cleanup 함수
    return () => {
      if (updateTimerRef.current) {
        clearTimeout(updateTimerRef.current);
        updateTimerRef.current = null;
      }
    };
  }, []); // 빈 의존성 배열

  const handleCellSelection = useCallback(
    (rowIndex, colId, subunitId, event) => {
      const key = createEditorKey(subunitId, rowIndex, colId, 0);

      if (event.shiftKey && selectionStart) {
        // Shift + 클릭으로 범위 선택
        const startRow = Math.min(selectionStart.rowIndex, rowIndex);
        const endRow = Math.max(selectionStart.rowIndex, rowIndex);

        const newSelectedCells = new Set();
        for (let i = startRow; i <= endRow; i++) {
          if (colId === selectionStart.colId) {
            newSelectedCells.add(createEditorKey(subunitId, i, colId, 0));
          }
        }
        setSelectedCells(newSelectedCells);
      } else {
        // 일반 클릭
        setSelectionStart({ rowIndex, colId });
        if (event.ctrlKey || event.metaKey) {
          // Ctrl/Cmd + 클릭으로 개별 선택/해제
          setSelectedCells((prev) => {
            const newSet = new Set(prev);
            if (newSet.has(key)) {
              newSet.delete(key);
            } else {
              newSet.add(key);
            }
            return newSet;
          });
        } else {
          // 일반 클릭으로 단일 선택
          setSelectedCells(new Set([key]));
        }
      }
    },
    [selectionStart]
  );

  // 1. 에디터 상태 초기화 함수 추가
  const resetEditorState = useCallback(() => {
    setActiveCell(null);
    setActiveEditorIndex(0);
    setShowMiniToolbar(false);
    setShowColorPicker(false);
    setShowBgColorPicker(false);
  }, []);

  const hideEditor = useCallback((key) => {
    const editor = quillRefs.current[key]?.getEditor();
    if (editor) {
      const editorContainer = editor.container;
      if (editorContainer) {
        editorContainer.classList.add("hidden-editor");
      }
    }
  }, []);

  const showEditor = useCallback((key) => {
    const editor = quillRefs.current[key]?.getEditor();
    if (editor) {
      const editorContainer = editor.container;
      if (editorContainer) {
        editorContainer.classList.remove("hidden-editor");
      }
    }
  }, []);

  // 2. 에디터 클릭 핸들러 수정
  const handleEditorClick = useCallback(
    (rowIndex, colId, subunitId, editorIndex = 0, event) => {
      if (event) {
        event.stopPropagation();
        if (event.handled) return;
        event.handled = true;
      }

      const key = createEditorKey(subunitId, rowIndex, colId, editorIndex);

      // Select 모드일 때의 처리
      if (isSelectMode) {
        // Select Mode에서는 content 체크 없이 모든 에디터 선택 가능
        setSelectedReviewEditors((prev) => {
          if (!Array.isArray(prev)) {
            return [key];
          }

          const isAlreadySelected = prev.includes(key);

          if (isAlreadySelected) {
            showEditor(key);
            return prev.filter((k) => k !== key);
          } else {
            hideEditor(key);
            return [...prev, key];
          }
        });
        return;
      }

      // 기존 에디터 클릭 로직
      if (event) {
        if (event.shiftKey || event.ctrlKey || event.metaKey) {
          handleCellSelection(rowIndex, colId, subunitId, event);
        } else {
          setSelectedCells(new Set([key]));
          setSelectionStart({ rowIndex, colId });
        }
      }

      if (activeCell === key) {
        resetEditorState();
        return;
      }

      setActiveCell(key);
      setActiveEditorIndex(editorIndex);
      if (onEditorClick) onEditorClick();
    },
    [
      isSelectMode,
      editors,
      hideEditor,
      showEditor,
      onEditorClick,
      resetEditorState,
      handleCellSelection,
    ]
  );

  // 인라인 스타일 정의
  const containerStyle = {
    fontFamily: `${font} !important`,
  };

  const editorStyle = {
    fontFamily: `${font} !important`,
  };

  // useEffect를 추가하여 폰트 변경 시 에디터 내용 업데이트
  // useEffect를 추가하여 폰트 변경 시 에디터 내용 업데이트
  useEffect(() => {
    Object.values(quillRefs.current).forEach((quillRef) => {
      if (quillRef && quillRef.getEditor()) {
        const editor = quillRef.getEditor();
        const length = editor.getLength();
        // 내용이 있는 경우에만 폰트 적용
        if (length > 0) {
          const currentContent = editor.getText();
          editor.formatText(0, length, "font-family", font, true);
          // 내용이 변경되었다면 복원
          if (editor.getText() !== currentContent) {
            editor.setText(currentContent);
            editor.formatText(
              0,
              currentContent.length,
              "font-family",
              font,
              true
            );
          }
        }
      }
    });
  }, [font]);
  // 드래그 시작
  const handleDragStart = useCallback(
    (e) => {
      e.preventDefault(); // 기본 동작 방지
      setIsDragging(true);
      dragStartX.current = e.clientX;
      dragStartWidths.current = { ...columnWidths };
    },
    [columnWidths]
  );

  // 드래그 중
  const handleDrag = useCallback(
    (e) => {
      if (!isDragging) return;

      requestAnimationFrame(() => {
        const deltaX = e.clientX - dragStartX.current;
        const containerWidth = tableRef.current?.offsetWidth || 0;
        const deltaPercentage = (deltaX / containerWidth) * 100;

        // 퍼센트 기반으로 새로운 너비 계산
        const newCol1Width = Math.max(
          5, // 최소 20%
          Math.min(
            80, // 최대 80%
            dragStartWidths.current.col1 + deltaPercentage
          )
        );
        const newCol2Width = 100 - newCol1Width;

        setColumnWidths({
          col1: newCol1Width,
          col2: newCol2Width,
        });
      });
    },
    [isDragging]
  );

  // 드래그 종료
  const handleDragEnd = useCallback(() => {
    if (!isDragging) return;

    setIsDragging(false);

    // 로컬 스토리지에만 저장
    if (subunitId) {
      localStorage.setItem(
        `columnWidths-${subunitId}`,
        JSON.stringify(columnWidths)
      );
    }
  }, [columnWidths, subunitId, isDragging]);

  // 이벤트 리스너 등록
  useEffect(() => {
    if (isDragging) {
      document.addEventListener("mousemove", handleDrag, { passive: true });
      document.addEventListener("mouseup", handleDragEnd);
      document.body.style.cursor = "col-resize";
    }

    return () => {
      document.removeEventListener("mousemove", handleDrag);
      document.removeEventListener("mouseup", handleDragEnd);
      document.body.style.cursor = "";
    };
  }, [isDragging, handleDrag, handleDragEnd]);

  useEffect(() => {
    return () => {
      // ref만 정리
      Object.keys(quillRefs.current).forEach((key) => {
        quillRefs.current[key] = null;
      });
    };
  }, []);

  // 외부 클릭 감지 핸들러
  const handleClickOutside = useCallback((event) => {
    if (toolbarRef.current && !toolbarRef.current.contains(event.target)) {
      setShowMiniToolbar(false);
      setShowColorPicker(false);
      setShowBgColorPicker(false);
    }
  }, []);

  // 외부 클릭 이벤트 리스너 등록
  useEffect(() => {
    if (showMiniToolbar) {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [showMiniToolbar, handleClickOutside]);

  // 분할 로직은 여기에
  useEffect(() => {
    const handleSplitColumn = () => {
      console.log("분할 이벤트 시작");

      if (selectedCells.size > 1) {
        console.log("다중 셀 분할 시작");
        setEditors((prev) => {
          const newEditors = { ...prev };

          // 선택된 셀들 중 -0으로 끝나는 기본 에디터들만 처리
          Array.from(selectedCells)
            .filter((key) => key.endsWith("-0"))
            .forEach((key) => {
              console.log("처리중인 셀:", key);
              const currentEditors = prev[key] || [{ width: 100, content: "" }];
              const currentEditor = currentEditors[0];
              const currentWidth = currentEditor?.width || 100;
              const splitWidth = currentWidth / 2;

              newEditors[key] = [
                {
                  ...currentEditor,
                  width: splitWidth,
                },
                {
                  width: splitWidth,
                  content: "",
                },
                ...currentEditors.slice(1),
              ];
            });

          return newEditors;
        });
        return; // 멀티 셀렉션 처리 후 종료
      }

      // 단일 셀 분할 로직
      if (!activeCell) {
        console.log("활성 셀 없음");
        return;
      }

      console.log("단일 셀 분할 시작:", activeCell);
      const match = activeCell.match(/^(.+)-(\d+)-(.+)-(\d+)$/);
      if (!match) {
        console.log("셀 형식 불일치");
        return;
      }

      const [_, subunitId, rowIndex, colId, currentIndex] = match;
      const baseKey = `${subunitId}-${rowIndex}-${colId}-0`; // 기본 키 생성
      console.log("기본 키:", baseKey);

      setEditors((prev) => {
        // 현재 에디터 배열 가져오기 (기본 키 사용)
        const currentEditors = prev[baseKey] || [{ width: 100, content: "" }];

        // 현재 활성화된 에디터의 내용과 너비 가져오기
        const currentEditor = currentEditors[parseInt(currentIndex)];
        if (!currentEditor) return prev;

        const currentContent = currentEditor?.content || "";
        const currentWidth = currentEditor?.width || 100;
        const splitWidth = currentWidth / 2;

        // 새로운 에디터 배열 생성
        const newEditors = [...currentEditors];

        // 현재 에디터 너비 수정
        newEditors[parseInt(currentIndex)] = {
          ...currentEditor,
          width: splitWidth,
        };

        // 새 에디터 추가
        newEditors.splice(parseInt(currentIndex) + 1, 0, {
          width: splitWidth,
          content: "",
        });

        return {
          ...prev,
          [baseKey]: newEditors,
        };
      });
    };

    window.addEventListener("splitColumn", handleSplitColumn);
    return () => window.removeEventListener("splitColumn", handleSplitColumn);
  }, [activeCell, activeEditorIndex, selectedCells]);
  // 드래그 시작 핸들러
  const handleDividerMouseDown = (e, cellKey, dividerIndex) => {
    e.preventDefault();
    setIsDraggingDivider(true);
    dragStartX.current = e.clientX;
    currentDraggingCell.current = { key: cellKey, dividerIndex };

    // 드래그 시작 시점의 에디터 너비들 저장
    const currentEditors = editors[cellKey] || [];
    dragStartWidths.current = currentEditors.map((editor) => editor.width);
  };

  // 드래그 중 핸들러
  const handleDividerDrag = useCallback(
    (e) => {
      if (!isDraggingDivider || !currentDraggingCell.current) return;

      const { key, dividerIndex } = currentDraggingCell.current;
      const cellElement = cellRef.current[key];
      if (!cellElement) return;

      const cellRect = cellElement.getBoundingClientRect();
      const deltaX = e.clientX - dragStartX.current;
      const deltaPercentage = (deltaX / cellRect.width) * 100;

      setEditors((prev) => {
        const newEditors = [...(prev[key] || [])];

        // 좌우 에디터의 너비 조정
        const leftWidth = Math.max(
          10,
          dragStartWidths.current[dividerIndex] + deltaPercentage
        );
        const rightWidth = Math.max(
          10,
          dragStartWidths.current[dividerIndex + 1] - deltaPercentage
        );

        // 전체 너비가 100%를 넘지 않도록 조정
        const totalOtherWidths = newEditors.reduce(
          (sum, editor, i) =>
            i !== dividerIndex && i !== dividerIndex + 1
              ? sum + editor.width
              : sum,
          0
        );
        const availableWidth = 100 - totalOtherWidths;

        if (leftWidth + rightWidth <= availableWidth) {
          newEditors[dividerIndex].width = leftWidth;
          newEditors[dividerIndex + 1].width = rightWidth;
        }

        return { ...prev, [key]: newEditors };
      });
    },
    [isDraggingDivider]
  );

  // 드래그 종료 핸들러
  const handleDividerMouseUp = useCallback(() => {
    if (currentDraggingCell.current) {
      const { key } = currentDraggingCell.current;
      const match = key.match(/^(.+)-(\d+)-(.+)-(\d+)$/);
      if (match) {
        const [_, subunitId, rowIndex, colId] = match;

        // Firebase 업데이트 부분 제거
        // editors 배열 형태로 데이터 구성
        // const cellEditors = editors[key] || [];
        // updateEditorData(subunitId, colId, parseInt(rowIndex), {
        //   editors: cellEditors,
        // });
      }
    }

    setIsDraggingDivider(false);
    currentDraggingCell.current = null;
    dragStartWidths.current = [];
  }, [editors]); // updateEditorData 의존성 제거

  // 드래그 이벤트 리스너
  useEffect(() => {
    if (isDraggingDivider) {
      window.addEventListener("mousemove", handleDividerDrag);
      window.addEventListener("mouseup", handleDividerMouseUp);
    }
    return () => {
      window.removeEventListener("mousemove", handleDividerDrag);
      window.removeEventListener("mouseup", handleDividerMouseUp);
    };
  }, [isDraggingDivider, handleDividerDrag, handleDividerMouseUp]);

  // 분할선 클릭 핸들러
  const handleDividerClick = (e, rowIndex, colId, editorIndex) => {
    if (!isRemovingSplit) return;
    e.stopPropagation();

    const baseKey = createEditorKey(subunitId, rowIndex, colId, 0);

    setEditors((prev) => {
      const currentEditors = [...(prev[baseKey] || [])];

      if (currentEditors.length > editorIndex + 1) {
        currentEditors[editorIndex].width +=
          currentEditors[editorIndex + 1].width;
        currentEditors.splice(editorIndex + 1, 1);
      }

      return {
        ...prev,
        [baseKey]: currentEditors,
      };
    });

    setIsRemovingSplit(false);
  };

  // isRemovingSplit 상태 변경 감지를 위한 useEffect 추가
  useEffect(() => {}, [isRemovingSplit]);

  const memoizedRenderEditor = useCallback(
    (rowIndex, colId, subunitId) => {
      const key = createEditorKey(subunitId, rowIndex, colId, 0);

      // 기존 렌더링 로직...
      const cellEditors = editors[key] || [
        {
          width: 100,
          content: editorContents[key] || "",
        },
      ];

      return (
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "100%",
            position: "relative",
          }}
          ref={(el) => (cellRef.current[key] = el)}
          onClick={(e) => {
            // 분할선 드래그 중에는 셀 선택 불가
            if (isDragging || isDraggingDivider) return;

            const editorContainer = e.target.closest("[data-editor-index]");
            const editorIndex = editorContainer
              ? parseInt(editorContainer.dataset.editorIndex)
              : 0;

            // 에디터 클릭이든 셀 클릭이든 모두 동일한 핸들러로 처리
            handleEditorClick(rowIndex, colId, subunitId, editorIndex, e);
          }}
        >
          {cellEditors.map((editor, index) => (
            <React.Fragment key={`editor-container-${index}`}>
              <div
                data-editor-index={index}
                style={{
                  width: `${editor.width}%`,
                  position: "relative",
                  minWidth: "10%",
                }}
              >
                <ReactQuill
                  ref={(el) => {
                    if (el) {
                      const editorKey = createEditorKey(
                        subunitId,
                        rowIndex,
                        colId,
                        index
                      );
                      quillRefs.current[editorKey] = el;

                      const editor = el.getEditor();
                      if (editor && !initializedEditors.has(editorKey)) {
                        // 에디터 초기화 시 필요한 설정들을 즉시 적용
                        editor.enable(true);
                        editor.focus();
                        editor.blur();

                        setInitializedEditors(
                          (prev) => new Set([...prev, editorKey])
                        );

                        // 에디터가 실제로 사용 가능한 상태가 되면 ready 설정
                        requestAnimationFrame(() => {
                          setEditorReady((prev) => ({
                            ...prev,
                            [editorKey]: true,
                          }));
                        });
                      }
                    }
                  }}
                  value={editor.content}
                  onChange={(content) =>
                    handleEditorChange(
                      content,
                      rowIndex,
                      colId,
                      subunitId,
                      index
                    )
                  }
                  onFocus={() => {
                    const editorKey = createEditorKey(
                      subunitId,
                      rowIndex,
                      colId,
                      index
                    );
                  }}
                  modules={modules}
                  formats={formats}
                  className="another-quill-editor"
                  style={{
                    ...editorStyle,
                    height: "29px",
                    overflow: "hidden",
                  }}
                  spellCheck={false}
                  readOnly={false}
                  preserveWhitespace={true}
                  tabIndex={0}
                />
              </div>
              {index < cellEditors.length - 1 && (
                <div
                  style={{
                    width: isRemovingSplit ? "6px" : "1px", // 리무브 모드일 때 더 굵게
                    height: "100%",
                    backgroundColor: isRemovingSplit
                      ? "#ff4444" // 리무브 모드일 때 빨간색
                      : isDraggingDivider &&
                        currentDraggingCell.current?.key === key &&
                        currentDraggingCell.current?.dividerIndex === index
                      ? "#fae150"
                      : "#e0e0e0",
                    cursor: isRemovingSplit ? "pointer" : "col-resize",
                    position: "relative",
                    zIndex: 1,
                    transition: "background-color 0.2s, width 0.2s", // width 트랜지션 추가
                    margin: isRemovingSplit ? "0 -2px" : "0", // 굵어질 때 좌우 여백 조정
                  }}
                  className={`editor-divider ${
                    isRemovingSplit ? "remove-mode" : ""
                  }`}
                  onMouseDown={
                    !isRemovingSplit
                      ? (e) => handleDividerMouseDown(e, key, index)
                      : undefined
                  }
                  onClick={
                    isRemovingSplit
                      ? (e) => handleDividerClick(e, rowIndex, colId, index)
                      : undefined
                  }
                />
              )}
            </React.Fragment>
          ))}
        </div>
      );
    },
    [
      isDraggingDivider,
      currentDraggingCell,
      editors,
      editorContents,
      modules,
      formats,
      editorStyle,
      handleEditorClick,
    ]
  );

  // 텍스트 색상 토글 함수 추가
  const toggleRedText = useCallback(() => {
    try {
      const editors = document.querySelectorAll(".ql-editor");

      editors.forEach((editor) => {
        // 1. 직접 color 스타일이 있는 요소
        const directColorElements = editor.querySelectorAll('[style*="color"]');

        // 2. 복합 스타일이 적용된 요소들 (bold, italic, background-color와 함께 있는 경우)
        const complexElements = editor.querySelectorAll(`
          strong[style*="color"],
          em[style*="color"],
          span[style*="background"][style*="color"],
          strong em[style*="color"],
          em strong[style*="color"],
          span[style*="color"][style*="background"]
        `);

        // 모든 대상 요소들을 하나의 배열로 합침
        const allTargetElements = [
          ...new Set([...directColorElements, ...complexElements]),
        ];

        allTargetElements.forEach((element) => {
          if (element.hasAttribute("data-temp-hidden")) {
            // 원래 색상으로 복원
            const originalColor = element.getAttribute("data-temp-hidden");
            element.style.setProperty("--original-color", originalColor);
            element.removeAttribute("data-temp-hidden");
          } else {
            // 현재 색상 저장 및 흰색으로 변경
            const computedColor = window.getComputedStyle(element).color;
            element.setAttribute("data-temp-hidden", computedColor);
            element.style.setProperty("--original-color", "#FFFFFF");
          }
        });
      });
    } catch (error) {
      console.error("Toggle text color error:", error);
    }
  }, []);

  // CSS 스타일 수정
  const style = document.createElement("style");
  style.textContent = `
    *[data-temp-hidden],
    *[data-temp-hidden] * {
      color: var(--original-color) !important;
    }
    
    /* 복합 스타일이 적용된 요소들에 대한 처리 */
    strong[data-temp-hidden],
    em[data-temp-hidden],
    span[data-temp-hidden],
    *[data-temp-hidden] strong,
    *[data-temp-hidden] em,
    *[data-temp-hidden] span {
      color: var(--original-color) !important;
    }
  `;
  document.head.appendChild(style);

  // 이벤트 리스너
  useEffect(() => {
    const handleToggleRedText = () => {
      toggleRedText();
    };

    window.addEventListener("toggleRedText", handleToggleRedText);
    return () =>
      window.removeEventListener("toggleRedText", handleToggleRedText);
  }, []); // 의존성 배열을 비워서 이벤트 리스너는 한 번만 등록되도록 수정

  // 컴포넌트 최상단에 useEffect 추가
  useEffect(() => {
    // 모든 에디터 요소에 대해 맞춤법 검사 비활성화 적용
    const disableSpellcheck = () => {
      const editors = document.querySelectorAll(".ql-editor");
      editors.forEach((editor) => {
        editor.setAttribute("spellcheck", "false");
        editor.setAttribute("data-gramm", "false");
        editor.setAttribute("data-gramm_editor", "false");
        editor.setAttribute("data-enable-grammarly", "false");

        // contenteditable 요소도 찾아서 비활성화
        const editableElements = editor.querySelectorAll("[contenteditable]");
        editableElements.forEach((el) => {
          el.setAttribute("spellcheck", "false");
          el.setAttribute("data-gramm", "false");
          el.setAttribute("data-gramm_editor", "false");
          el.setAttribute("data-enable-grammarly", "false");
        });
      });
    };

    disableSpellcheck();
    // MutationObserver로 동적으로 추가되는 요소들도 감시
    const observer = new MutationObserver(disableSpellcheck);
    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });

    return () => observer.disconnect();
  }, []);

  // 키보드 이벤트 핸들러 수정
  useEffect(() => {
    // 이전 리스너가 있으면 제거
    if (keydownListenerRef.current) {
      const tableWrapper = document.querySelector(".another-table-wrapper");
      if (tableWrapper) {
        tableWrapper.removeEventListener("keydown", keydownListenerRef.current);
      }
    }

    // 새로운 키다운 핸들러
    const handleKeyDown = (e) => {
      // Enter 키 처리
      if (e.key === "Enter") {
        e.preventDefault();
        e.stopPropagation();

        const currentRow = e.target.closest("tr");
        const nextRow = currentRow?.nextElementSibling;
        if (!nextRow) return;

        const currentCell = e.target.closest("td");
        const cellIndex = Array.from(currentRow.cells).indexOf(currentCell);
        const nextCell = nextRow.cells[cellIndex];

        if (nextCell) {
          const currentEditorKey = currentCell.dataset.editorId;
          const nextEditor = nextCell.querySelector(".ql-editor");

          if (nextEditor && quillRefs.current[currentEditorKey]) {
            const currentQuill =
              quillRefs.current[currentEditorKey].getEditor();

            if (currentQuill) {
              if (currentQuill.selection) {
                currentQuill.selection.composing = false;
              }
              currentQuill.setSelection(null);

              requestAnimationFrame(() => {
                nextEditor.focus();
              });
            }
          }
        }
      }

      // Backspace 키 처리
      if (e.key === "Backspace") {
        // 다중 셀이 선택된 경우에만 처리
        if (selectedCells.size > 1) {
          e.preventDefault();
          e.stopPropagation();

          setEditors((prev) => {
            const newEditors = { ...prev };
            selectedCells.forEach((key) => {
              const currentEditors = prev[key] || [{ content: "", width: 100 }];
              newEditors[key] = currentEditors.map((editor) => ({
                ...editor,
                content: "",
              }));
            });
            return newEditors;
          });

          window.dispatchEvent(new Event("saveAllData"));
        }
      }
    };

    // 현재 리스너 저장
    keydownListenerRef.current = (e) => {
      if (e.target.closest(".ql-editor")) {
        handleKeyDown(e);
      }
    };

    // 새 리스너 등록
    const tableWrapper = document.querySelector(".another-table-wrapper");
    if (tableWrapper) {
      tableWrapper.addEventListener("keydown", keydownListenerRef.current);
    }

    // cleanup
    return () => {
      if (tableWrapper) {
        tableWrapper.removeEventListener("keydown", keydownListenerRef.current);
      }
    };
  }, [selectedCells, quillRefs]); // quillRefs 의존성 추가

  const hasContent = useCallback((key) => {
    const editor = quillRefs.current[key]?.getEditor();
    return editor && editor.getText().trim().length > 0;
  }, []);

  // 이벤트 핸들러들
  const handleToggleFirstColumn = useCallback(
    (event) => {
      const colId = "col1";

      for (let rowIndex = 0; rowIndex < 29; rowIndex++) {
        const baseKey = createEditorKey(subunitId, rowIndex, colId, 0);

        // 해당 셀의 모든 에디터 찾기
        const editorIndices = Object.keys(editorContents)
          .filter((key) => {
            const matches = key.startsWith(baseKey.slice(0, -2));
            return matches;
          })
          .map((key) => {
            const parts = key.split("-");
            return parseInt(parts[parts.length - 1]);
          });

        editorIndices.forEach((editorIndex) => {
          const key = createEditorKey(subunitId, rowIndex, colId, editorIndex);

          if (hasContent(key)) {
            if (hiddenEditors.col1.has(key)) {
              showEditor(key);
              setHiddenEditors((prev) => ({
                ...prev,
                col1: new Set([...prev.col1].filter((k) => k !== key)),
              }));
            } else {
              hideEditor(key);
              setHiddenEditors((prev) => ({
                ...prev,
                col1: new Set([...prev.col1, key]),
              }));
            }
          }
        });
      }
    },
    [
      hiddenEditors,
      subunitId,
      hideEditor,
      showEditor,
      hasContent,
      editorContents,
    ]
  );

  const handleToggleSecondColumn = useCallback(
    (event) => {
      const colId = "col2";

      for (let rowIndex = 0; rowIndex < 29; rowIndex++) {
        // 해당 셀의 모든 에디터를 찾기 위한 기본 키
        const baseKey = `${subunitId}-${rowIndex}-${colId}`;

        // 해당 셀의 모든 에디터 인덱스 찾기
        const editorIndices = Object.keys(editorContents)
          .filter((key) => key.startsWith(baseKey))
          .map((key) => {
            const parts = key.split("-");
            return parseInt(parts[parts.length - 1]);
          });

        // 각 에디터에 대해 처리
        editorIndices.forEach((editorIndex) => {
          const key = `${subunitId}-${rowIndex}-${colId}-${editorIndex}`;

          if (hasContent(key)) {
            if (hiddenEditors.col2.has(key)) {
              showEditor(key);
              setHiddenEditors((prev) => ({
                ...prev,
                col2: new Set([...prev.col2].filter((k) => k !== key)),
              }));
            } else {
              hideEditor(key);
              setHiddenEditors((prev) => ({
                ...prev,
                col2: new Set([...prev.col2, key]),
              }));
            }
          }
        });
      }
    },
    [
      hiddenEditors,
      subunitId,
      hideEditor,
      showEditor,
      hasContent,
      editorContents,
    ]
  );

  const handleToggleShowRowByRow = useCallback(
    (event) => {
      const { isActive, hideAll, showAll } = event.detail;
      const colId = "col1";

      if (hideAll) {
        const newHidden = new Set();

        for (let rowIndex = 0; rowIndex < 29; rowIndex++) {
          const baseKey = createEditorKey(subunitId, rowIndex, colId, 0);
          const baseKeyPattern = baseKey.slice(0, -2);

          // quillRefs에서 키를 찾도록 수정
          const matchingKeys = Object.keys(quillRefs.current).filter((k) =>
            k.startsWith(baseKeyPattern)
          );

          if (matchingKeys.length > 0) {
            matchingKeys.forEach((key) => {
              const hasText = hasContent(key);
              if (hasText) {
                hideEditor(key);
                newHidden.add(key);
              }
            });
          }
        }

        setHiddenEditors((prev) => ({ ...prev, col1: newHidden }));
        setVisibleEditors((prev) => ({ ...prev, col1: new Set() }));
      }
    },
    [subunitId, hiddenEditors, hideEditor, showEditor, hasContent]
  );

  const handleToggleShowRowByRowCol2 = useCallback(
    (event) => {
      const { isActive, hideAll, showAll } = event.detail;
      const colId = "col2";

      if (hideAll) {
        const newHidden = new Set();

        for (let rowIndex = 0; rowIndex < 29; rowIndex++) {
          const baseKey = createEditorKey(subunitId, rowIndex, colId, 0);
          const baseKeyPattern = baseKey.slice(0, -2);

          // quillRefs에서 키를 찾도록 수정
          const matchingKeys = Object.keys(quillRefs.current).filter((k) =>
            k.startsWith(baseKeyPattern)
          );

          if (matchingKeys.length > 0) {
            matchingKeys.forEach((key) => {
              const hasText = hasContent(key);
              if (hasText) {
                hideEditor(key);
                newHidden.add(key);
              }
            });
          }
        }

        setHiddenEditors((prev) => ({ ...prev, col2: newHidden }));
        setVisibleEditors((prev) => ({ ...prev, col2: new Set() }));
      } else if (showAll) {
        hiddenEditors.col2.forEach((key) => showEditor(key));
        setHiddenEditors((prev) => ({ ...prev, col2: new Set() }));
        setVisibleEditors((prev) => ({ ...prev, col2: new Set() }));
      }
    },
    [subunitId, hiddenEditors, hideEditor, showEditor, hasContent]
  );

  const handleShowNextRow = useCallback(
    (event) => {
      const { columnId } = event.detail;
      const hiddenKeys = Array.from(hiddenEditors[columnId]);
      const visibleKeys = Array.from(visibleEditors[columnId]);

      if (hiddenKeys.length > visibleKeys.length) {
        const nextKey = hiddenKeys[visibleKeys.length];
        showEditor(nextKey);
        setVisibleEditors((prev) => ({
          ...prev,
          [columnId]: new Set([...prev[columnId], nextKey]),
        }));
      }
    },
    [hiddenEditors, visibleEditors, showEditor]
  );
  // Exit 모드 핸들러
  const handleExitReviewMode = useCallback(
    (event) => {
      // 모든 숨겨진 에디터 복구
      hiddenEditors.col1.forEach((key) => {
        showEditor(key);
      });
      hiddenEditors.col2.forEach((key) => {
        showEditor(key);
      });

      // 상태 초기화
      setHiddenEditors({
        col1: new Set(),
        col2: new Set(),
      });
      setVisibleEditors({
        col1: new Set(),
        col2: new Set(),
      });
    },
    [hiddenEditors, showEditor]
  );

  // 이벤트 리스너에 Exit 모드 관련 이벤트 추가
  useEffect(() => {
    window.addEventListener("exitReviewMode", handleExitReviewMode);

    return () => {
      window.removeEventListener("exitReviewMode", handleExitReviewMode);
    };
  }, [handleExitReviewMode]);

  // 이벤트 리스너 등록
  useEffect(() => {
    // 토글 이벤트
    window.addEventListener("toggleFirstColumn", handleToggleFirstColumn);
    window.addEventListener("toggleSecondColumn", handleToggleSecondColumn);

    // Hide/Show Rows 이벤트
    window.addEventListener("toggleShowRowByRow", handleToggleShowRowByRow);
    window.addEventListener(
      "toggleShowRowByRowCol2",
      handleToggleShowRowByRowCol2
    );

    // Next Row 이벤트
    window.addEventListener("showNextRow", handleShowNextRow);

    // 분할선 제거 이벤트 리스너 추가
    const handleRemoveSplitEvent = (event) => {
      setIsRemovingSplit(event.detail.isActive);
    };
    window.addEventListener("removeSplit", handleRemoveSplitEvent);

    return () => {
      // 토글 이벤트 제거
      window.removeEventListener("toggleFirstColumn", handleToggleFirstColumn);
      window.removeEventListener(
        "toggleSecondColumn",
        handleToggleSecondColumn
      );

      // Hide/Show Rows 이벤트 제거
      window.removeEventListener(
        "toggleShowRowByRow",
        handleToggleShowRowByRow
      );
      window.removeEventListener(
        "toggleShowRowByRowCol2",
        handleToggleShowRowByRowCol2
      );

      // Next Row 이벤트 제거
      window.removeEventListener("showNextRow", handleShowNextRow);

      // 분할선 제거 이벤트 cleanup 추가
      window.removeEventListener("removeSplit", handleRemoveSplitEvent);
    };
  }, [
    handleToggleFirstColumn,
    handleToggleSecondColumn,
    handleToggleShowRowByRow,
    handleToggleShowRowByRowCol2,
    handleShowNextRow,
  ]);

  const getTableClassName = () => {
    const baseClass = "another-table-wrapper"; // 기본 클래스명 변경

    switch (layout) {
      case "layout2":
        return `${baseClass} layout-2`;
      case "layout3":
        return `${baseClass} layout-3`;
      case "layout4":
        return `${baseClass} layout-4`;
      case "layout5":
        return `${baseClass} layout-5`;
      case "layout6":
        return `${baseClass} layout-6`;
      case "layout7":
        return `${baseClass} layout-7`;
      case "layout8":
        return `${baseClass} layout-8`;
      default:
        return baseClass;
    }
  };

  useEffect(() => {
    const newWidths =
      layout === "layout7" || layout === "layout8"
        ? { col1: 100, col2: 0 }
        : layout === "layout5" || layout === "layout6"
        ? { col1: 50, col2: 50 }
        : { col1: 20, col2: 80 };

    setColumnWidths(newWidths);
  }, [layout]);

  // 별도의 useEffect로 columnWidths 변경 감지
  useEffect(() => {}, [columnWidths]);

  // 3. 문서 클릭 이벤트에 대한 처리 추가
  useEffect(() => {
    const handleDocumentClick = (e) => {
      // 에디터 영역 외 클릭 시 상태 초기화
      const isEditorClick = e.target.closest(".another-quill-editor");
      const isToolbarClick = e.target.closest(".another-note-mini-toolbar");

      if (!isEditorClick && !isToolbarClick) {
        resetEditorState();
      }
    };

    document.addEventListener("click", handleDocumentClick);
    return () => document.removeEventListener("click", handleDocumentClick);
  }, [resetEditorState]);

  // 드래그 시작 핸들러
  const handleCellDragStart = useCallback(
    (e, rowIndex, colId) => {
      e.stopPropagation();
      const cellKey = createEditorKey(subunitId, rowIndex, colId, 0);

      // 선택된 셀들이 있는지 확인
      const isMultiSelection = selectedCells.size > 0;

      if (isMultiSelection && selectedCells.has(cellKey)) {
        // 선택된 모든 셀의 모든 에디터 내용 수집 (editors 상태에서 직접 가져옴)
        const selectedContents = Array.from(selectedCells).map((key) => ({
          key,
          editors: editors[key] || [{ content: "", width: 100 }], // 전체 에디터 배열 가져오기
        }));

        setDraggedCell({
          isMulti: true,
          startKey: cellKey,
          contents: selectedContents,
          rowIndex,
          colId,
        });
      } else {
        // 단일 셀 드래그 (전체 에디터 배열)
        setDraggedCell({
          isMulti: false,
          rowIndex,
          colId,
          key: cellKey,
          editors: editors[cellKey] || [{ content: "", width: 100 }], // 전체 에디터 배열
        });
      }

      e.dataTransfer.setData("text/plain", "");
    },
    [subunitId, selectedCells, editors] // editors 의존성 추가
  );
  // 드래그 오버 핸들러
  const handleCellDragOver = useCallback(
    (e, rowIndex, colId) => {
      // 같은 열에서만 드래그 효과 표시
      if (draggedCell?.colId === colId) {
        e.preventDefault();
        setDragOverCell({ rowIndex, colId });
      }
    },
    [draggedCell]
  );

  const handleCellDrop = useCallback(
    async (e, targetRowIndex, targetColId) => {
      e.preventDefault();

      // 같은 위치에 드롭된 경우 취소 처리
      if (
        draggedCell?.rowIndex === targetRowIndex &&
        draggedCell?.colId === targetColId
      ) {
        setDraggedCell(null);
        setDragOverCell(null);
        return;
      }

      // 다른 열로 드래그된 경우 취소 처리
      if (!draggedCell || draggedCell.colId !== targetColId) {
        setDraggedCell(null);
        setDragOverCell(null);
        return;
      }

      try {
        if (draggedCell.isMulti) {
          const selectedRows = Array.from(selectedCells)
            .map((key) => {
              const [_, row] = key.match(/^.+-(\d+)-.+$/);
              return parseInt(row);
            })
            .sort((a, b) => a - b);

          const startRow = selectedRows[0];
          const endRow = selectedRows[selectedRows.length - 1];
          const allRows = Array.from(
            { length: endRow - startRow + 1 },
            (_, i) => startRow + i
          );

          const rowCount = allRows.length;
          const canMove =
            targetRowIndex + rowCount - 1 <= 28 && targetRowIndex >= 0;

          if (!canMove) {
            return;
          }

          setEditors((prev) => {
            const newEditors = { ...prev };

            const sourceContents = allRows.map((row) => {
              const baseKey = createEditorKey(subunitId, row, targetColId, 0);
              return {
                row,
                editors: [...(prev[baseKey] || [{ content: "", width: 100 }])],
              };
            });

            allRows.forEach((row) => {
              const oldKey = createEditorKey(subunitId, row, targetColId, 0);
              newEditors[oldKey] = [{ content: "", width: 100 }];
            });

            sourceContents.forEach((source, index) => {
              const newRow = targetRowIndex + index;
              const newKey = createEditorKey(subunitId, newRow, targetColId, 0);
              newEditors[newKey] = source.editors;
            });

            return newEditors;
          });
        } else {
          setEditors((prev) => {
            const newEditors = { ...prev };
            const sourceKey = createEditorKey(
              subunitId,
              draggedCell.rowIndex,
              targetColId,
              0
            );
            const targetKey = createEditorKey(
              subunitId,
              targetRowIndex,
              targetColId,
              0
            );

            const sourceEditors = prev[sourceKey] || [
              { content: "", width: 100 },
            ];
            const targetEditors = prev[targetKey] || [
              { content: "", width: 100 },
            ];

            newEditors[sourceKey] = [...targetEditors];
            newEditors[targetKey] = [...sourceEditors];

            return newEditors;
          });
        }
      } finally {
        setDraggedCell(null);
        setDragOverCell(null);
        setSelectedCells(new Set());
      }
    },
    [draggedCell, subunitId, selectedCells]
  );

  const getTdStyle = useCallback(
    (rowIndex, colId, key) => {
      const width =
        layout === "layout7" || layout === "layout8"
          ? "100%"
          : columnWidths[colId];

      const isCurrentDraggedCell =
        draggedCell?.colId === colId && draggedCell?.rowIndex === rowIndex;
      const isDraggedOver =
        dragOverCell?.rowIndex === rowIndex && dragOverCell?.colId === colId;

      // 해당 셀의 모든 에디터 내용 확인
      const baseKey = `${key.split("-").slice(0, -1).join("-")}-0`;
      const cellEditors = editors[baseKey] || [];
      const hasContent = cellEditors.some(
        (editor) => editor.content && editor.content.trim().length > 0
      );

      const isReviewMode = layout === "layout7" || layout === "layout8";

      return {
        ...containerStyle,
        width,
        position: "relative",
        border: selectedCells.has(key)
          ? "1px solid #fae150"
          : isDraggedOver && !isCurrentDraggedCell
          ? "2px dashed #fae150"
          : "1px solid #e0e0e0",
        backgroundColor: isReviewMode && hasContent ? "#f5f5f5" : "transparent",
        opacity: isCurrentDraggedCell ? 0.6 : 1,
        transition: "border 0.2s ease",
        // 리뷰 모드에서 텍스트가 있는 셀은 pointer-events none 처리
        ...(isReviewMode &&
          hasContent && {
            pointerEvents: "none",
          }),
      };
    },
    [
      containerStyle,
      columnWidths,
      layout,
      selectedCells,
      dragOverCell,
      draggedCell,
      editors, // editors 의존성 추가
    ]
  );

  useEffect(() => {
    setCurrentIds(courseId, subunitId);
  }, [courseId, subunitId, setCurrentIds]);

  useEffect(() => {
    const handleInsertEmptyRow = async () => {
      if (!activeCell) {
        return;
      }

      const match = activeCell.match(
        new RegExp(`${subunitId}-(\\d+)-(col\\d+)-`)
      );

      if (!match) {
        return;
      }

      const targetRow = parseInt(match[1]);
      const colId = match[2];

      setEditors((prev) => {
        const newEditors = { ...prev };

        for (let i = 27; i >= targetRow; i--) {
          const currentKey = `${subunitId}-${i}-${colId}-0`;
          const nextKey = `${subunitId}-${i + 1}-${colId}-0`;

          if (newEditors[currentKey]) {
            newEditors[nextKey] = newEditors[currentKey];
          }
        }

        const newKey = `${subunitId}-${targetRow}-${colId}-0`;
        newEditors[newKey] = [
          {
            content: "",
            width: 100,
          },
        ];

        return newEditors;
      });
    };

    window.addEventListener("insertEmptyRow", handleInsertEmptyRow);
    return () => {
      window.removeEventListener("insertEmptyRow", handleInsertEmptyRow);
    };
  }, [activeCell, subunitId]);

  const loadData = useCallback(async () => {
    if (authLoading || !courseId || !subunitId) {
      return;
    }

    setIsTableLoading(true); // 로딩 시작

    try {
      setCurrentIds(courseId, subunitId);
      const data = await loadTableData(subunitId);

      wasLoadedRef.current = true;

      if (data?.tableData) {
        // 컬럼 너비 설정
        if (data.columnWidths) {
          // 저장된 너비가 있으면 그대로 사용
          setColumnWidths(data.columnWidths);
        } else {
          // 저장된 너비가 없을 때만 레이아웃별 기본값 적용
          if (layout === "layout7" || layout === "layout8") {
            setColumnWidths({ col1: 100, col2: 0 });
          } else if (layout === "layout5" || layout === "layout6") {
            setColumnWidths({ col1: 50, col2: 50 });
          } else {
            setColumnWidths({ col1: 20, col2: 80 });
          }
        }

        // 에디터 데이터 처리
        const newEditors = {};
        Object.entries(data.tableData).forEach(([rowIndex, rowData]) => {
          Object.entries(rowData).forEach(([colId, cellData]) => {
            const editorKey = `${subunitId}-${rowIndex}-${colId}-0`;

            if (cellData && typeof cellData === "object") {
              const editors = Object.values(cellData).map((editor) => ({
                content: editor.content || "",
                width: editor.width || 100,
              }));
              newEditors[editorKey] = editors;
            } else {
              newEditors[editorKey] = [
                {
                  content: "",
                  width: 100,
                },
              ];
            }
          });
        });

        setEditors(newEditors);
        loadCompleteRef.current = true;
        onLoadComplete && onLoadComplete(true);
      }

      loadCompleteRef.current = true;
      onLoadComplete && onLoadComplete(true);
    } catch (error) {
      wasLoadedRef.current = false;
      loadCompleteRef.current = false;
      onLoadComplete && onLoadComplete(false);
    } finally {
      setIsTableLoading(false); // 로딩 완료
    }
  }, [
    courseId,
    subunitId,
    authLoading,
    setCurrentIds,
    loadTableData,
    layout,
    onLoadComplete,
  ]);

  useEffect(() => {
    const shouldLoadData = !authLoading && courseId && subunitId;

    if (shouldLoadData && lastLoadedSubunitRef.current !== subunitId) {
      setEditors({});
      setInitializedEditors(new Set());

      lastLoadedSubunitRef.current = subunitId;

      setTimeout(() => {
        loadData();
      }, 100);
    }
  }, [loadData, authLoading, courseId, subunitId]);

  // 1. Firebase 실시간 업데이트 핸들러
  const handleFirebaseUpdate = useCallback(
    (snapshot) => {
      const data = snapshot.data();
      if (!data?.tableData) return;

      // tableData 구조에 맞춰 editors 상태 업데이트
      const newEditors = {};
      data.tableData.forEach((row, rowIndex) => {
        Object.entries(row).forEach(([colId, cellData]) => {
          if (cellData?.editors) {
            const key = createEditorKey(subunitId, rowIndex, colId, 0);
            newEditors[key] = cellData.editors;
          }
        });
      });

      // 현재 입력 중이 아닐 때만 업데이트
      if (!isTypingRef.current) {
        setEditors((prev) => ({
          ...prev,
          ...newEditors,
        }));
      }
    },
    [subunitId]
  );

  // 2. Firebase 실시간 리스너 설정
  useEffect(() => {
    if (!subunitId || !currentUser || !courseId) {
      return;
    }

    const docRef = doc(
      db,
      "users",
      currentUser.uid,
      "courses",
      courseId,
      "curriculum/tableData/subunits",
      subunitId
    );

    const unsubscribe = onSnapshot(
      docRef,
      (snapshot) => {
        handleFirebaseUpdate(snapshot);
      },
      (error) => {}
    );

    return () => {
      unsubscribe();
    };
  }, [subunitId, currentUser, courseId, handleFirebaseUpdate]);

  // 3. 기존 debouncedFirebaseUpdate 수정 (더 자세한 로깅 추가)
  const debouncedFirebaseUpdate = useCallback(
    debounce((subunitId, colId, rowIndex, editors) => {
      updateEditorData(subunitId, colId, rowIndex, { editors }).catch(
        (error) => {
          // 실패 시 로컬 스토리지에 백업
          const backupKey = `backup_${subunitId}_${colId}_${rowIndex}`;
          localStorage.setItem(backupKey, JSON.stringify(editors));
        }
      );
    }, 300), // 디바운스 시간을 300ms로 줄임
    [updateEditorData]
  );

  const handleEditorChange = useCallback(
    (content, rowIndex, colId, subunitId, editorIndex = 0) => {
      // HTML 정리
      const cleanContent = content
        .replace(/<p><br><\/p>/g, "")
        .replace(/\n/g, "");

      // 에디터 키 생성
      const key = createEditorKey(subunitId, rowIndex, colId, 0);

      // editors 상태 즉시 업데이트
      const updatedEditors = [...(editors[key] || [])];
      updatedEditors[editorIndex] = {
        ...updatedEditors[editorIndex],
        content: cleanContent,
        width: updatedEditors[editorIndex]?.width || 100,
      };

      setEditors((prev) => ({
        ...prev,
        [key]: updatedEditors,
      }));
    },
    [editors]
  );
  // 언마운트 시 저장을 위한 useEffect 수정
  useEffect(() => {
    let isSaving = false;

    return async () => {
      if (isSaving) {
        return;
      }
      isSaving = true;

      try {
        window.dispatchEvent(new Event("saveAllData"));

        await new Promise((resolve, reject) => {
          let retryCount = 0;
          const maxRetries = 3;
          const timeout = setTimeout(() => {
            if (retryCount < maxRetries) {
              retryCount++;
              window.dispatchEvent(new Event("saveAllData"));
            } else {
              resolve(); // 에러 대신 resolve 처리
            }
          }, 10000);

          const checkSaveComplete = (event) => {
            if (event.detail?.status === "complete") {
              clearTimeout(timeout);
              window.removeEventListener("saveComplete", checkSaveComplete);
              resolve();
            } else if (event.detail?.status === "error") {
              clearTimeout(timeout);
              window.removeEventListener("saveComplete", checkSaveComplete);
              resolve();
            }
          };

          window.addEventListener("saveComplete", checkSaveComplete);
        });
      } catch (error) {
        console.error("📢 저장 중 에러:", error);
      } finally {
        isSaving = false;
      }
    };
  }, [subunitId]);

  // 열 너비 변경 시 로컬 스토리지 저장
  useEffect(() => {
    if (subunitId && columnWidths) {
      localStorage.setItem(
        `columnWidths-${subunitId}`,
        JSON.stringify(columnWidths)
      );
    }
  }, [columnWidths, subunitId]);

  // saveAllData 이벤트 핸들러 수정
  useEffect(() => {
    const handleSaveAllData = async () => {
      const tableData = {};

      for (let rowIndex = 0; rowIndex < 29; rowIndex++) {
        tableData[rowIndex] = {
          col1: {},
          col2: {},
        };

        ["col1", "col2"].forEach((colId) => {
          const baseKey = `${subunitId}-${rowIndex}-${colId}-0`;
          const editorsList = editors[baseKey] || [
            {
              content: "",
              width: 100,
            },
          ];

          editorsList.forEach((editor, index) => {
            tableData[rowIndex][colId][index] = {
              content: editor.content || "",
              width: editor.width || 100,
            };
          });
        });
      }

      try {
        await saveAllTableData(subunitId, tableData, columnWidths);

        window.dispatchEvent(
          new CustomEvent("saveComplete", {
            detail: { status: "complete" },
          })
        );
      } catch (error) {
        window.dispatchEvent(
          new CustomEvent("saveComplete", {
            detail: { status: "error", error },
          })
        );
      }
    };

    window.addEventListener("saveAllData", handleSaveAllData);
    return () => window.removeEventListener("saveAllData", handleSaveAllData);
  }, [editors, columnWidths, subunitId, saveAllTableData]);
  // 클립보드 상태 추가
  const [clipboardData, setClipboardData] = useState(null);

  // 복사 함수
  const handleCopyCells = useCallback(() => {
    if (selectedCells.size === 0) return;

    const copiedData = Array.from(selectedCells).map((key) => {
      const [sid, rowIndex, colId, editorIndex] = key.split("-");
      return {
        key,
        editors: editors[key] || [],
      };
    });

    try {
      localStorage.setItem(
        "tableClipboard",
        JSON.stringify({
          data: copiedData,
          timestamp: Date.now(),
        })
      );
    } catch (error) {}
  }, [selectedCells, editors]);

  const handlePasteCells = useCallback(() => {
    if (!activeCell) {
      return;
    }

    try {
      const savedData = localStorage.getItem("tableClipboard");
      if (!savedData) {
        return;
      }

      const { data: clipboardData, timestamp } = JSON.parse(savedData);

      if (Date.now() - timestamp > 3600000) {
        localStorage.removeItem("tableClipboard");
        return;
      }

      const matches = activeCell.match(/^(.+)-(\d+)-(.+)-(\d+)$/);
      if (!matches) {
        return;
      }

      const [_, targetSubunitId, targetRow, targetCol, targetEditorIndex] =
        matches;
      const targetRowNum = parseInt(targetRow);

      const newEditors = { ...editors };
      const sourceStartMatches = clipboardData[0].key.match(
        /^(.+)-(\d+)-(.+)-(\d+)$/
      );
      const startRow = parseInt(sourceStartMatches[2]);

      clipboardData.forEach((item) => {
        const itemMatches = item.key.match(/^(.+)-(\d+)-(.+)-(\d+)$/);
        const sourceRow = parseInt(itemMatches[2]);
        const relativeOffset = sourceRow - startRow;
        const newRowIndex = targetRowNum + relativeOffset;

        if (newRowIndex >= 0 && newRowIndex < 29) {
          const newKey = `${targetSubunitId}-${newRowIndex}-${targetCol}-0`;
          newEditors[newKey] = [...item.editors];
        }
      });

      setEditors(newEditors);
    } catch (error) {}
  }, [activeCell, editors]);

  useEffect(() => {
    const handleCopyCommand = () => handleCopyCells();
    const handlePasteCommand = () => handlePasteCells();

    window.addEventListener("copySelectedCells", handleCopyCommand);
    window.addEventListener("pasteCopiedCells", handlePasteCommand);

    return () => {
      window.removeEventListener("copySelectedCells", handleCopyCommand);
      window.removeEventListener("pasteCopiedCells", handlePasteCommand);
    };
  }, [handleCopyCells, handlePasteCells]);

  // 1. 5분마다 자동 저장하는 useEffect
  useEffect(() => {
    let autoSaveInterval;

    if (subunitId && courseId && !isTableLoading) {
      console.log("📢 5분 자동 저장 시작");

      autoSaveInterval = setInterval(() => {
        console.log(
          "📢 5분 주기 자동 저장 실행:",
          new Date().toLocaleTimeString()
        );
        window.dispatchEvent(new Event("saveAllData"));
      }, 300000); // 5분
    }

    return () => {
      if (autoSaveInterval) {
        clearInterval(autoSaveInterval);
      }
    };
  }, [subunitId, courseId, isTableLoading]);

  // 2. 페이지 숨김/컴퓨터 잠듦 감지 저장
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden") {
        console.log("📢 페이지 숨김/컴퓨터 잠듦 감지 - 최종 상태 저장");
        window.dispatchEvent(new Event("saveAllData"));
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  // beforeunload 이벤트 핸들러 수정
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      const needsSave = Object.keys(editors).length > 0;

      if (needsSave) {
        // 저장 시작 시간 기록
        const saveStartTime = Date.now();

        try {
          // 저장 시작 상태 기록
          sessionStorage.setItem(
            "lastSaveAttempt",
            JSON.stringify({
              timestamp: saveStartTime,
              status: "started",
              data: {
                editors,
                columnWidths,
                subunitId,
              },
            })
          );

          // 동기적 저장 실행
          window.dispatchEvent(new Event("saveAllData"));

          // 저장 완료 표시
          sessionStorage.setItem(
            "lastSaveAttempt",
            JSON.stringify({
              timestamp: Date.now(),
              status: "completed",
              data: {
                editors,
                columnWidths,
                subunitId,
              },
            })
          );

          // 의도적으로 약간의 지연 추가
          let startTime = Date.now();
          while (Date.now() - startTime < 150) {
            // 강제 지연
          }
        } catch (error) {
          sessionStorage.setItem(
            "lastSaveAttempt",
            JSON.stringify({
              timestamp: Date.now(),
              status: "failed",
              error: error.message,
            })
          );
        }
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload, {
      capture: true,
    });
    return () =>
      window.removeEventListener("beforeunload", handleBeforeUnload, {
        capture: true,
      });
  }, [editors, columnWidths, subunitId]);

  // 컴포넌트 마운트 시 이전 저장 상태 확인
  useEffect(() => {
    const lastSaveAttempt = sessionStorage.getItem("lastSaveAttempt");
    if (lastSaveAttempt) {
      const saveData = JSON.parse(lastSaveAttempt);

      // 이전 저장이 실패했거나 완료되지 않은 경우
      if (saveData.status !== "completed") {
        // 백업 데이터로 복원 시도
        if (saveData.data) {
          setEditors(saveData.data.editors);
          setColumnWidths(saveData.data.columnWidths);
        }
      }

      // 확인 후 삭제
      sessionStorage.removeItem("lastSaveAttempt");
    }
  }, []);

  const keydownListenerRef = useRef(null);

  useEffect(() => {
    // 이전 리스너가 있으면 제거
    if (keydownListenerRef.current) {
      const tableWrapper = document.querySelector(".another-table-wrapper");
      if (tableWrapper) {
        tableWrapper.removeEventListener("keydown", keydownListenerRef.current);
      }
    }

    // 새로운 키다운 핸들러
    const handleKeyDown = (e) => {
      // Enter 키 처리
      if (e.key === "Enter") {
        e.preventDefault();
        e.stopPropagation();

        const currentRow = e.target.closest("tr");
        const nextRow = currentRow?.nextElementSibling;
        if (!nextRow) return;

        const currentCell = e.target.closest("td");
        const cellIndex = Array.from(currentRow.cells).indexOf(currentCell);
        const nextCell = nextRow.cells[cellIndex];

        if (nextCell) {
          const currentEditorKey = currentCell.dataset.editorId;
          const nextEditor = nextCell.querySelector(".ql-editor");

          if (nextEditor && quillRefs.current[currentEditorKey]) {
            const currentQuill =
              quillRefs.current[currentEditorKey].getEditor();

            if (currentQuill) {
              if (currentQuill.selection) {
                currentQuill.selection.composing = false;
              }
              currentQuill.setSelection(null);

              requestAnimationFrame(() => {
                nextEditor.focus();
              });
            }
          }
        }
      }

      // Backspace 키 처리
      if (e.key === "Backspace") {
        // 다중 셀이 선택된 경우에만 처리
        if (selectedCells.size > 1) {
          e.preventDefault();
          e.stopPropagation();

          setEditors((prev) => {
            const newEditors = { ...prev };
            selectedCells.forEach((key) => {
              const currentEditors = prev[key] || [{ content: "", width: 100 }];
              newEditors[key] = currentEditors.map((editor) => ({
                ...editor,
                content: "",
              }));
            });
            return newEditors;
          });

          window.dispatchEvent(new Event("saveAllData"));
        }
      }
    };

    // 현재 리스너 저장
    keydownListenerRef.current = (e) => {
      if (e.target.closest(".ql-editor")) {
        handleKeyDown(e);
      }
    };

    // 새 리스너 등록
    const tableWrapper = document.querySelector(".another-table-wrapper");
    if (tableWrapper) {
      tableWrapper.addEventListener("keydown", keydownListenerRef.current);
    }

    // cleanup
    return () => {
      if (tableWrapper) {
        tableWrapper.removeEventListener("keydown", keydownListenerRef.current);
      }
    };
  }, [selectedCells, quillRefs]); // quillRefs 의존성 추가

  useEffect(() => {
    const handleKeyDown = (e) => {
      // Ctrl+K (Mac의 경우 Cmd+K) 감지
      if (e.key === "k" && (e.ctrlKey || e.metaKey)) {
        e.preventDefault();
        e.stopPropagation();

        // 기존 splitColumn 이벤트 발생
        window.dispatchEvent(new Event("splitColumn"));
      }
    };

    // 에디터에 이벤트 리스너 추가
    const editors = document.querySelectorAll(".ql-editor");
    editors.forEach((editor) => {
      editor.addEventListener("keydown", handleKeyDown);
    });

    // cleanup
    return () => {
      const editors = document.querySelectorAll(".ql-editor");
      editors.forEach((editor) => {
        editor.removeEventListener("keydown", handleKeyDown);
      });
    };
  }, [activeCell]);
  // Select 모드 이벤트 리스너
  useEffect(() => {
    const handleSelectModeToggle = (e) => {
      setIsSelectMode(e.detail.active);
    };

    window.addEventListener("selectMode", handleSelectModeToggle);

    return () => {
      window.removeEventListener("selectMode", handleSelectModeToggle);
    };
  }, []);

  // Select 모드 상태 변경 감지
  useEffect(() => {
    if (!isSelectMode && selectedReviewEditors.length > 0) {
      selectedReviewEditors.forEach((key) => {
        const editor = quillRefs.current[key]?.getEditor();
        if (editor?.container) {
          editor.container.classList.remove("hidden-editor");
        }
      });
      setSelectedReviewEditors([]);
    }
  }, [isSelectMode]);

  return (
    <div className={getTableClassName()} style={containerStyle}>
      {isTableLoading ? (
        <div className="table-loading-spinner">
          <div className="spinner"></div>
        </div>
      ) : (
        <>
          <table
            style={{
              ...containerStyle,
              width:
                layout === "layout7" || layout === "layout8" ? "100%" : "table",
            }}
            ref={tableRef}
          >
            <colgroup>
              <col style={{ width: `${columnWidths.col1}%` }} />
              <col style={{ width: `${columnWidths.col2}%` }} />
            </colgroup>
            <tbody>
              {Array(29)
                .fill()
                .map((_, rowIndex) => (
                  <tr key={rowIndex}>
                    {["col1", "col2"].map((colId) => {
                      const key = `${subunitId}-${rowIndex}-${colId}-0`;
                      return (
                        <td
                          key={key}
                          data-editor-id={key}
                          onClick={(e) => {
                            // 분할선 드래그 중에는 셀 선택 불가
                            if (isDragging || isDraggingDivider) return;

                            const editorContainer = e.target.closest(
                              "[data-editor-index]"
                            );
                            const editorIndex = editorContainer
                              ? parseInt(editorContainer.dataset.editorIndex)
                              : 0;

                            // 에디터 클릭이든 셀 클릭이든 모두 동일한 핸들러로 처리
                            handleEditorClick(
                              rowIndex,
                              colId,
                              subunitId,
                              editorIndex,
                              e
                            );
                          }}
                          style={getTdStyle(rowIndex, colId, key)}
                          draggable={!isDragging && !isDraggingDivider}
                          onDragStart={(e) =>
                            !isDragging &&
                            !isDraggingDivider &&
                            handleCellDragStart(e, rowIndex, colId)
                          }
                          onDragOver={(e) => {
                            if (!isDragging && !isDraggingDivider) {
                              e.preventDefault();
                              setDragOverCell({ rowIndex, colId });
                            }
                          }}
                          onDragLeave={() =>
                            !isDragging &&
                            !isDraggingDivider &&
                            setDragOverCell(null)
                          }
                          onDrop={(e) =>
                            !isDragging &&
                            !isDraggingDivider &&
                            handleCellDrop(e, rowIndex, colId)
                          }
                        >
                          {memoizedRenderEditor(rowIndex, colId, subunitId)}
                          {colId === "col1" &&
                            layout !== "layout7" &&
                            layout !== "layout8" && (
                              <div
                                className={`another-component-v2-resize-handle ${
                                  isDragging ? "dragging" : ""
                                }`}
                                onMouseDown={handleDragStart}
                              />
                            )}
                        </td>
                      );
                    })}
                  </tr>
                ))}
            </tbody>
          </table>

          {showMiniToolbar && (
            <div
              ref={toolbarRef}
              className="another-note-mini-toolbar"
              style={{
                position: "absolute",
                left: `${miniToolbarPosition.x}px`,
                top: `${miniToolbarPosition.y}px`,
                zIndex: 9999,
              }}
            >
              <button
                className="another-note-style-btn"
                onClick={() => applyStyle("bold")}
              >
                B
              </button>
              <button
                className="another-note-style-btn"
                onClick={() => applyStyle("italic")}
              >
                I
              </button>
              <div className="another-note-color-picker">
                <button
                  className="another-note-style-btn"
                  onClick={() => setShowColorPicker(!showColorPicker)}
                >
                  A
                </button>
                {showColorPicker && (
                  <div className="another-color-picker-popup">
                    {textColors.map((color, index) => (
                      <button
                        key={index}
                        className="another-color-option"
                        style={{ backgroundColor: color }}
                        onClick={() => {
                          applyStyle("color", color);
                          setShowColorPicker(false);
                        }}
                      />
                    ))}
                  </div>
                )}
              </div>
              <div className="another-note-color-picker">
                <button
                  className="another-note-style-btn"
                  onClick={() => setShowBgColorPicker(!showBgColorPicker)}
                >
                  H
                </button>
                {showBgColorPicker && (
                  <div className="another-color-picker-popup">
                    {highlightColors.map((color, index) => (
                      <button
                        key={index}
                        className="another-color-option"
                        style={{
                          backgroundColor:
                            color === "remove" ? "transparent" : color,
                          border:
                            color === "remove" ? "1px solid #ccc" : "none",
                        }}
                        onClick={() => {
                          applyStyle("background", color);
                          setShowBgColorPicker(false);
                        }}
                      />
                    ))}
                  </div>
                )}
              </div>
              <div className="another-note-align-buttons">
                <button
                  className="another-note-style-btn"
                  onClick={() => applyStyle("align", "left")}
                >
                  <img
                    src="/toolbaricons/inuse/align-left.svg"
                    alt="왼쪽 정렬"
                    className="another-toolbar-icon"
                    width="14"
                    height="14"
                  />
                </button>
                <button
                  className="another-note-style-btn"
                  onClick={() => applyStyle("align", "center")}
                >
                  <img
                    src="/toolbaricons/inuse/align-center.svg"
                    alt="가운데 정렬"
                    className="another-toolbar-icon"
                    width="14"
                    height="14"
                  />
                </button>
                <button
                  className="another-note-style-btn"
                  onClick={() => applyStyle("align", "right")}
                >
                  <img
                    src="/toolbaricons/inuse/align-right.svg"
                    alt="오른쪽 정렬"
                    className="another-toolbar-icon"
                    width="14"
                    height="14"
                  />
                </button>
              </div>
            </div>
          )}
        </>
      )}

      <style>
        {`
          .editor-divider.removable {
            background-color: #ff4444 !important;
            cursor: pointer !important;
          }
          .editor-divider.removable:hover {
            background-color: #ff6666 !important;
            
          }
          
          /* 맞춤법 검사 비활성화를 위한 포괄적인 스타일 */
          .ql-editor,
          .ql-editor *,
          [contenteditable],
          [contenteditable] * {
            -webkit-spell-check: false !important;
            -moz-spell-check: false !important;
            spellcheck: false !important;
            -webkit-spelling-error: none !important;
            -moz-spelling-error: none !important;
            spelling-error: none !important;
            text-decoration-skip-ink: none !important;
           
            text-decoration-line: none !important;
            -ms-spelling-error: none !important;
          }
          
          /* Grammarly 비활성화 */
          grammarly-  extension {
            display: none !important;
          }
          
          /* 추가적인 맞춤법 검사 관련 스타일 무효화 */
          .ql-editor p,
          .ql-editor span {
          
            border-bottom: none !important;

          }
          
          /* MS Word 스타일 맞춤법 검사 비활성화 */
          .ql-editor [class*="Proofing_"] {
            display: none !important;
          }
          
          .another-quill-editor .ql-editor {
            padding: 0px 8px;
            min-height: 24px;
            max-height: 24px;
            overflow: hidden;
            white-space: nowrap;
            cursor: text;
            user-select: text;
          }
                 .another-quill-editor .ql-editor:focus {
            outline: none;
          
          } 
          
          .another-quill-editor .ql-disabled {
            background-color: transparent !important;
          }
          
          .another-quill-editor .ql-editor p {
            margin: 0;
            line-height: 24px;
          }

          /* 로딩 스피너 스타일 */
          .table-loading-spinner {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;ss
            height: 200px;
          }

          .spinner {
            width: 40px;
            height: 40px;
            border: 3px solid #f3f3f3;
            border-top: 3px solid #fae150;
            border-radius: 50%;
            animation: spin 1s linear infinite;
          }

          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}
      </style>
    </div>
  );
};

export default AnotherComponentVer2;
