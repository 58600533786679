import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { auth } from "../firebase/firebase";
import "../css/common.css";

const PrivateRoute = React.memo(({ children }) => {
  const { user, loading } = useAuth();
  const [isTimeout, setIsTimeout] = useState(false);

  useEffect(() => {
    const checkSession = async () => {
      if (user) {
        const sessionToken = sessionStorage.getItem("userSession");

        if (!sessionToken) {
          setIsTimeout(true);
          await auth.signOut();
          return <Navigate to="/login" />;
        }

        const lastActivity = sessionStorage.getItem("lastActivityTime");
        const currentTime = new Date().getTime();

        if (
          lastActivity &&
          currentTime - parseInt(lastActivity) > 30 * 60 * 1000
        ) {
          setIsTimeout(true);
          await auth.signOut();
          return <Navigate to="/login" />;
        }

        sessionStorage.setItem("lastActivityTime", currentTime.toString());
      }
    };

    if (user && !sessionStorage.getItem("userSession")) {
      sessionStorage.setItem("userSession", Date.now().toString());
      sessionStorage.setItem("lastActivityTime", Date.now().toString());
    }

    checkSession();
  }, [user]);

  useEffect(() => {
    const updateActivity = () => {
      if (user) {
        sessionStorage.setItem("lastActivityTime", Date.now().toString());
      }
    };

    const events = ["mousedown", "keydown", "scroll", "touchstart"];
    events.forEach((event) => {
      window.addEventListener(event, updateActivity);
    });

    return () => {
      events.forEach((event) => {
        window.removeEventListener(event, updateActivity);
      });
    };
  }, [user]);

  if (!loading && !user) {
    return (
      <Navigate
        to="/login"
        state={{ message: "로그인이 필요한 서비스입니다." }}
      />
    );
  }

  if (loading) {
    return (
      <div className="loading-overlay">
        {isTimeout ? (
          <div className="session-timeout-message">
            <p>Session expired. Please login again.</p>
            <Navigate to="/login" />
          </div>
        ) : (
          <div className="loading-spinner"></div>
        )}
      </div>
    );
  }

  if (!user) {
    return <Navigate to="/login" />;
  }

  return children;
});

export default PrivateRoute;
