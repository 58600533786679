import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../css/LandingPage.css";
import landingImage from "../assets/img/landingimg_1.svg";
import landing2Image from "../assets/img/landing_2.png";
import landing3Image from "../assets/img/landing_3.png";
import landing4Image from "../assets/img/landing_4.png";
import landingImg2 from "../assets/img/landingimg_2.svg";
import landingImg3 from "../assets/img/landingimg_3.svg";
import landingImg4 from "../assets/img/landingimg_4.svg";
import landingImg5 from "../assets/img/landingimg5.svg";
import landingImg6 from "../assets/img/landingimg6.svg";
import landingImg7 from "../assets/img/landingimg7.svg";
import landingimg5 from "../assets/img/landingimg5.svg";
import landingimg6 from "../assets/img/landingimg6.svg";
import landingimg7 from "../assets/img/landingimg7.svg";
import { useAuth } from "../contexts/AuthContext";
import { auth } from "../firebase/firebase";
import { checkSubscriptionStatus } from "../utils/subscription";

const LandingPage = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);
  const [featureImageIndex, setFeatureImageIndex] = useState(0);

  const heroContent = [
    {
      title: "All-in-One Learning Space",
      subtitle: (
        <>
          Notes, Reviews, Planner, Flashcards
          <br />
          Everything you need to succeed, all in one place
        </>
      ),
      image: landingImage,
    },
  ];

  useEffect(() => {
    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("visible");
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, {
      threshold: 0.1,
    });

    document.querySelectorAll(".feature-content").forEach((element) => {
      observer.observe(element);
    });

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    const checkDevice = () => {
      const mobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      const width = window.innerWidth;
      setIsMobile(mobile && width < 768); // 태블릿은 제외
    };

    checkDevice();
    window.addEventListener("resize", checkDevice);
    return () => window.removeEventListener("resize", checkDevice);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setFeatureImageIndex((current) => (current + 1) % 3);
    }, 3000); // 3초마다 이미지 전환

    return () => clearInterval(interval);
  }, []);

  const handleStartClick = () => {
    if (!user) {
      navigate("/signup");
    } else {
      navigate("/dashboard");
    }
  };

  return (
    <div className="pao-container">
      <header
        className="pao-header"
        style={{
          padding: "1rem 2rem",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "white",
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div
          className="pao-logo"
          style={{
            cursor: "pointer",
          }}
        >
          <Link
            to="/"
            style={{
              textDecoration: "none",
              color: "#414042",
            }}
          >
            <span
              style={{
                color: "#414042",
                fontSize: "24px",
                fontFamily: "Dela Gothic One, cursive",
                lineHeight: "31px",
                display: "inline-block",
              }}
            >
              Redo Note.
            </span>
          </Link>
        </div>
        {!isMobile && (
          <div className="pao-header-buttons">
            {user ? (
              <Link
                to="/dashboard"
                className="pao-header-btn primary"
                style={{
                  backgroundColor: "#fae150",
                  color: "#231c16",
                  border: "2px solid #231c16",
                  padding: "12px 24px",
                  borderRadius: "24px",
                  fontSize: "16px",
                  fontWeight: "700",
                  textDecoration: "none",
                  display: "inline-block",
                  transition: "all 0.2s ease",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                }}
              >
                Dashboard
              </Link>
            ) : (
              <button
                onClick={() => navigate("/signup")}
                className="pao-header-btn primary"
                style={{
                  cursor: "pointer",
                  width: "150px",
                  height: "45px",
                  padding: "0px 8px",
                  border: "2px solid #414042",
                  boxSizing: "border-box",
                  backgroundColor: "#ffffff",
                  color: "#414042",
                  fontSize: "16px",
                  fontFamily: "Montserrat",
                  fontWeight: 600,
                  lineHeight: "21px",
                  outline: "none",
                  marginLeft: "auto",
                }}
              >
                Get Started
              </button>
            )}
          </div>
        )}
      </header>

      <div
        style={{
          backgroundColor: "#414042",
          color: "white",
          textAlign: "center",
          padding: "8px",
          fontSize: "14px",
          fontFamily: "Montserrat",
        }}
      >
        🚀 현재 베타 서비스 중입니다. 피드백을 환영합니다!
      </div>

      <main>
        <section
          className="pao-hero-section"
          style={{
            height: "500px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            backgroundColor: "#feefd8",
            color: "#414042",
            padding: "0 2rem",
          }}
        >
          <div
            className="hero-top"
            style={{
              display: "flex",
              width: "100%",
              maxWidth: "1200px",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "2rem",
            }}
          >
            <div
              className="pao-hero-content"
              style={{
                flex: "1",
                textAlign: "left",
                maxWidth: "600px",
              }}
            >
              <h1
                className="pao-main-title"
                style={{
                  color: "#414042",
                  fontSize: "40px",
                  fontFamily: "Dela Gothic One",
                  lineHeight: "52px",
                  marginBottom: "0.5rem",
                }}
              >
                {heroContent[0].title}
              </h1>
              <p
                className="subtitle"
                style={{
                  color: "#414042",
                  fontSize: "16px",
                  fontFamily: "Montserrat",
                  fontWeight: 500,
                  lineHeight: "21px",
                  marginTop: "1rem",
                  marginBottom: "2rem",
                }}
              >
                {heroContent[0].subtitle}
              </p>
              <div className="button-group">
                <button
                  className="main-button primary-action"
                  onClick={handleStartClick}
                  style={{
                    cursor: "pointer",
                    top: "390px",
                    left: "80px",
                    width: "238px",
                    height: "54px",
                    padding: "0px 8px",
                    border: 0,
                    boxSizing: "border-box",
                    backgroundColor: "#414042",
                    color: "#ffffff",
                    fontSize: "16px",
                    fontFamily: "Montserrat",
                    fontWeight: 700,
                    lineHeight: "21px",
                    outline: "none",
                  }}
                >
                  시작하기
                </button>
              </div>
            </div>
            <div
              className="hero-image"
              style={{
                flex: "1",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src="/landing/hero-image.svg"
                alt="Workspace Illustration"
                style={{
                  maxWidth: "100%",
                  height: "auto",
                }}
              />
            </div>
          </div>
        </section>

        <section className="feature-section">
          <div className="feature-content">
            <h2 className="feature-title">Plan Smarter, Learn Better</h2>

            <div className="feature-cards-grid">
              {[
                {
                  image: "/landing/sublanding1.svg",
                  title: "Calendar",
                  subtitle:
                    "Monthly, Weekly, Yearly, Daily views for flexible planning",
                },
                {
                  image: "/landing/sublanding2.svg",
                  title: "Todo List",
                  subtitle: "Organize and manage your tasks efficiently",
                },
                {
                  image: "/landing/sublanding3.svg",
                  title: "Track Progress",
                  subtitle:
                    "Monitor study time and review progress at a glance",
                },
              ].map((item, index) => (
                <div key={index} className="feature-card">
                  <img
                    src={item.image}
                    alt={item.title}
                    className="feature-card-image"
                  />
                  <h3 className="feature-card-title">{item.title}</h3>
                  <p className="feature-card-subtitle">{item.subtitle}</p>
                </div>
              ))}
            </div>

            <div className="feature-image" style={{ margin: 0, padding: 0 }}>
              {[landingImg2, landingImg3, landingImg4].map((img, index) => (
                <img
                  key={index}
                  src={img}
                  alt={`Feature Preview ${index + 1}`}
                  className="preview-image"
                  style={{
                    display: featureImageIndex === index ? "block" : "none",
                  }}
                />
              ))}
              <div className="carousel-controls">
                {[0, 1, 2].map((index) => (
                  <button
                    key={index}
                    className={`carousel-dot ${
                      featureImageIndex === index ? "active" : ""
                    }`}
                    onClick={() => setFeatureImageIndex(index)}
                  />
                ))}
              </div>
            </div>
          </div>
        </section>

        <section className="feature-section">
          <div className="feature-content">
            <h2 className="feature-title">Review Again, Learn Again</h2>

            <div className="feature-cards-grid">
              {[
                {
                  image: "/landing/sublanding4.svg",
                  title: "Self Test",
                  subtitle:
                    "Test your knowledge with customizable quizzes and assessments",
                },
                {
                  image: "/landing/sublanding5.svg",
                  title: "Flashcards",
                  subtitle:
                    "Master concepts through spaced repetition and active recall",
                },
              ].map((item, index) => (
                <div key={index} className="feature-card">
                  <img
                    src={item.image}
                    alt={item.title}
                    className="feature-card-image"
                  />
                  <h3 className="feature-card-title">{item.title}</h3>
                  <p className="feature-card-subtitle">{item.subtitle}</p>
                </div>
              ))}
            </div>

            <div className="feature-image">
              <video
                src="/video/landing_review.mov"
                alt="Focus Mode Preview"
                className="preview-video"
                autoPlay
                loop
                muted
              />
            </div>
          </div>
        </section>

        <section className="feature-section">
          <div className="feature-content">
            <h2 className="feature-title">Rewrite Again, Achieve Again</h2>

            <div className="feature-cards-grid">
              {[
                {
                  image: "/landing/sublanding6.svg",
                  title: "Handwritten fonts",
                  subtitle:
                    "Create aesthetically pleasing notes with handwritten style",
                },
                {
                  image: "/landing/sublanding7.svg",
                  title: "Various Templates",
                  subtitle:
                    "Choose from multiple note templates for any study purpose",
                },
              ].map((item, index) => (
                <div key={index} className="feature-card">
                  <img
                    src={item.image}
                    alt={item.title}
                    className="feature-card-image"
                  />
                  <h3 className="feature-card-title">{item.title}</h3>
                  <p className="feature-card-subtitle">{item.subtitle}</p>
                </div>
              ))}
            </div>

            <div className="feature-image">
              <img
                src={landingimg6}
                alt="Analytics Preview"
                className="preview-image"
              />
            </div>
          </div>
        </section>
      </main>
      <footer
        className="business-info"
        style={{
          backgroundColor: "#faaf3d",
          color: "#231c16",
          padding: "48px 0",
          marginTop: "80px",
        }}
      >
        <div
          className="info-container"
          style={{
            maxWidth: "1200px",
            margin: "0 auto",
            padding: "0 24px",
          }}
        >
          <div
            className="footer-content"
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "40px",
            }}
          >
            {/* 로고 섹션 */}
            <div
              className="footer-logo-section"
              style={{
                flex: "0 0 300px",
              }}
            >
              <Link
                to="/"
                style={{
                  textDecoration: "none",
                  color: "#231c16",
                  fontSize: "24px",
                  fontFamily: "Dela Gothic One, cursive",
                  marginBottom: "16px",
                  display: "block",
                }}
              >
                Re-do Note.
              </Link>
              <div
                style={{
                  color: "rgba(35, 28, 22, 0.8)",
                  fontSize: "14px",
                  fontWeight: "400",
                  marginTop: "8px",
                }}
              >
                © 2024 Redo Note. All rights reserved.
              </div>
            </div>

            {/* 메뉴 섹션들 */}
            <div
              className="footer-menus"
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(4, 1fr)",
                gap: "40px",
                flex: "1",
              }}
            >
              {/* 제품 정보 */}
              <div className="footer-section">
                <h3
                  style={{
                    color: "#231c16",
                    marginBottom: "16px",
                    fontSize: "16px",
                    fontWeight: "600",
                  }}
                >
                  Product
                </h3>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                  }}
                >
                  <Link
                    to="/note-taking"
                    style={{
                      color: "#231c16",
                      textDecoration: "none",
                      fontSize: "14px",
                    }}
                  >
                    Note-taking
                  </Link>
                  <Link
                    to="/review-mode"
                    style={{
                      color: "#231c16",
                      textDecoration: "none",
                      fontSize: "14px",
                    }}
                  >
                    Review mode
                  </Link>
                  <Link
                    to="/planner"
                    style={{
                      color: "#231c16",
                      textDecoration: "none",
                      fontSize: "14px",
                    }}
                  >
                    Planner
                  </Link>
                </div>
              </div>

              {/* 회사 정보 */}
              <div className="footer-section">
                <h3
                  style={{
                    color: "#231c16",
                    marginBottom: "16px",
                    fontSize: "16px",
                    fontWeight: "600",
                  }}
                >
                  Company
                </h3>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                  }}
                >
                  <Link
                    to="/about"
                    style={{
                      color: "#231c16",
                      textDecoration: "none",
                      fontSize: "14px",
                    }}
                  >
                    About
                  </Link>
                  <Link
                    to="/licensing"
                    style={{
                      color: "#231c16",
                      textDecoration: "none",
                      fontSize: "14px",
                    }}
                  >
                    Licensing
                  </Link>
                </div>
              </div>

              {/* 지원 */}
              <div className="footer-section">
                <h3
                  style={{
                    color: "#231c16",
                    marginBottom: "16px",
                    fontSize: "16px",
                    fontWeight: "600",
                  }}
                >
                  Support
                </h3>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                  }}
                >
                  <Link
                    to="/contact"
                    style={{
                      color: "#231c16",
                      textDecoration: "none",
                      fontSize: "14px",
                    }}
                  >
                    Help Center
                  </Link>
                  <Link
                    to="/refund-policy"
                    style={{
                      color: "#231c16",
                      textDecoration: "none",
                      fontSize: "14px",
                    }}
                  >
                    Cancellation Policy
                  </Link>
                </div>
              </div>

              {/* 법적 정보 */}
              <div className="footer-section">
                <h3
                  style={{
                    color: "#231c16",
                    marginBottom: "16px",
                    fontSize: "16px",
                    fontWeight: "600",
                  }}
                >
                  Legal
                </h3>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                  }}
                >
                  <Link
                    to="/terms"
                    style={{
                      color: "#231c16",
                      textDecoration: "none",
                      fontSize: "14px",
                    }}
                  >
                    Terms of Use
                  </Link>
                  <Link
                    to="/privacy"
                    style={{
                      color: "#231c16",
                      textDecoration: "none",
                      fontSize: "14px",
                    }}
                  >
                    Privacy Policy
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;
