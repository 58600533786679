import React, {
  useState,
  useRef,
  useEffect,
  forwardRef,
  useCallback,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useCurriculum } from "../contexts/CurriculumContext";
import arrowDown from "../assets/icons/inuse/caret-right.svg";
import arrowRight from "../assets/icons/inuse/caret-down.svg";
import { v4 as uuidv4 } from "uuid";
import ContextMenu from "./ContextMenu";
import LoadingSpinner from "./LoadingSpinner";
import { useTable } from "../contexts/TableContext";
import { useImage } from "../contexts/ImageContext";
import EventEmitter from "../utils/eventEmitter";
import { deleteSubunitData } from "../utils/subunitDeleteHelper";
import { auth } from "../firebase/firebase";

const LeftBar = forwardRef(
  (
    {
      isOpen,
      onToggle,
      onWidthChange,
      style,
      currentUnitId,
      currentSubunit,
      showSections,
      showChapters,
    },
    ref
  ) => {
    const {
      curriculum,
      addNewSubunit,
      reorderSubunits,
      handleSubunitNameChange,
      deleteSubunit,
    } = useCurriculum();
    const { setCurrentIds: setTableIds } = useTable();
    const { setCurrentIds: setImageIds } = useImage();
    const navigate = useNavigate();
    const { courseId, courseName, subunitId } = useParams();

    const sidebarContentRef = useRef(null);
    const [expandedUnits, setExpandedUnits] = useState({});
    const [expandedHeights, setExpandedHeights] = useState({});
    const [editingSubunit, setEditingSubunit] = useState(null);
    const [contextMenu, setContextMenu] = useState({
      visible: false,
      x: 0,
      y: 0,
      subunitInfo: null,
    });
    const [isDeletingSubunit, setIsDeletingSubunit] = useState(false);

    // Toggle Sidebar
    const toggleSidebar = () => {
      if (onToggle) {
        onToggle(!isOpen);
      }
    };

    // Handle Subunit Click
    const handleSubunitClick = useCallback(
      (unit, subunit) => {
        const newSubunitId = subunit.id;
        const newUrl = `/courses/${courseId}/curriculum/${courseName}/unit/${unit.unitId}/subunit/${newSubunitId}`;

        navigate(newUrl);

        setTableIds(courseId, newSubunitId);
        setImageIds(courseId, newSubunitId);
      },
      [courseId, navigate, courseName, setTableIds, setImageIds, subunitId]
    );

    // Add Unit Toggle Handler
    const toggleUnit = (unitId) => {
      setExpandedUnits((prev) => ({
        ...prev,
        [unitId]: !prev[unitId],
      }));
    };

    // Automatically set toggle state of current selected unit
    useEffect(() => {
      if (currentUnitId) {
        setExpandedUnits((prev) => ({
          ...prev,
          [currentUnitId]: true,
        }));
      }
    }, [currentUnitId]);
    // Add useEffect for initial selection state
    useEffect(() => {
      return () => {};
    }, []);

    useEffect(() => {
      if (subunitId && currentUnitId) {
        const currentSubunitData = curriculum.subunits[currentUnitId]?.find(
          (subunit) => subunit.id === subunitId
        );
        if (currentSubunitData) {
          setExpandedUnits((prev) => ({
            ...prev,
            [currentUnitId]: true,
          }));
        }
      }
    }, [subunitId, currentUnitId, curriculum.subunits]);

    useEffect(() => {
      if (currentUnitId) {
        setExpandedUnits((prev) => ({
          ...prev,
          [currentUnitId]: true,
        }));
      }
    }, [currentUnitId]);

    const onDragEnd = (result) => {
      if (!result.destination) return;

      const sourceUnitId = result.source.droppableId;
      const destUnitId = result.destination.droppableId;
      const sourceIndex = result.source.index;
      const destIndex = result.destination.index;
      const draggedSubunitId = result.draggableId;

      // 서브유닛 순서 변경
      reorderSubunits(sourceUnitId, destUnitId, sourceIndex, destIndex);

      // 현재 보고 있는 서브유닛을 다른 유닛으로 이동한 경우
      if (draggedSubunitId === subunitId && sourceUnitId !== destUnitId) {
        // URL 업데이트
        const newUrl = `/courses/${courseId}/curriculum/${courseName}/unit/${destUnitId}/subunit/${draggedSubunitId}`;
        navigate(newUrl);

        // 테이블과 이미지 컨텍스트 업데이트
        setTableIds(courseId, draggedSubunitId);
        setImageIds(courseId, draggedSubunitId);
      }
    };

    // Add handler for drag start
    const onDragStart = () => {
      // Logic needed when drag starts
    };

    // Modify handler for drag update
    const onDragUpdate = (update) => {
      if (update.destination) {
        const targetUnitId = update.destination.droppableId;
        // Auto expand unit when dragged item is over it
        setExpandedUnits((prev) => ({
          ...prev,
          [targetUnitId]: true,
        }));
      } else if (update.over) {
        // When dragged item is over a closed unit
        const hoveredUnitId = update.over.droppableId;
        setExpandedUnits((prev) => ({
          ...prev,
          [hoveredUnitId]: true,
        }));
      }
    };

    // Handle Context Menu Display
    const handleContextMenu = (e, unit, subunit) => {
      e.preventDefault();
      setContextMenu({
        visible: true,
        x: e.clientX,
        y: e.clientY,
        subunitInfo: { unitId: unit.unitId, subunitId: subunit.id },
      });
    };

    // Close Context Menu
    const handleCloseContextMenu = () => {
      setContextMenu({ visible: false, x: 0, y: 0, subunitInfo: null });
    };

    // Handle Subunit Delete
    const handleDeleteSubunit = async () => {
      if (contextMenu.subunitInfo) {
        const { unitId, subunitId } = contextMenu.subunitInfo;
        const userId = auth.currentUser?.uid;

        setIsDeletingSubunit(true);

        try {
          const currentUnitSubunits = curriculum.subunits[unitId] || [];
          const subunitIndex = currentUnitSubunits.findIndex(
            (subunit) => subunit.id === subunitId
          );
          let nextSubunit = null;
          if (currentUnitSubunits.length > 1) {
            nextSubunit =
              currentUnitSubunits[subunitIndex + 1] ||
              currentUnitSubunits[subunitIndex - 1];
          }

          await deleteSubunitData(userId, courseId, subunitId);
          await deleteSubunit(unitId, subunitId);

          EventEmitter.emit("subunitDeleted", {
            courseId,
            subunitId,
          });

          if (currentSubunit?.id === subunitId || subunitId === subunitId) {
            if (nextSubunit) {
              navigate(
                `/courses/${courseId}/curriculum/${courseName}/unit/${unitId}/subunit/${nextSubunit.id}`
              );
            } else {
              navigate(`/courses/${courseId}/curriculum/${courseName}`);
            }
          }
        } catch (error) {
          // Error handling
        } finally {
          setIsDeletingSubunit(false);
          handleCloseContextMenu();
        }
      }
    };

    // Add useEffect for scrolling to selected subunit
    useEffect(() => {
      if (!isOpen) return;

      // Add slight delay to allow DOM updates
      const timer = setTimeout(() => {
        const selectedElement = document.querySelector(
          ".leftbar-subunit.selected"
        );
        if (selectedElement) {
          selectedElement.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
      }, 300);

      return () => clearTimeout(timer);
    }, [isOpen, currentUnitId, currentSubunit?.id, subunitId]);

    // 확장/축소 토글 함수 추가
    const toggleExpandHeight = (unitId) => {
      setExpandedHeights((prev) => ({
        ...prev,
        [unitId]: !prev[unitId],
      }));
    };

    // 서브유닛 순서 변경
    const handleDragEnd = (result) => {
      reorderSubunits(result, currentUnitId);
    };

    // 서브유닛 이름 변경
    const handleNameChange = (subunitId, newName) => {
      handleSubunitNameChange(currentUnitId, subunitId, newName);
    };

    return (
      <DragDropContext
        onDragStart={onDragStart}
        onDragUpdate={onDragUpdate}
        onDragEnd={onDragEnd}
      >
        <div
          ref={ref}
          className={`left-bar ${isOpen ? "open" : "closed"}`}
          style={style}
        >
          <div className="sidebar-header">
            <button className="toggle-button" onClick={toggleSidebar}>
              {isOpen ? "<<" : ">>"}
            </button>
          </div>

          {isOpen && (
            <div className="leftbar-content" ref={sidebarContentRef}>
              <h2 className="leftbar-course-title">{courseName}</h2>
              <div className="leftbar-scroll">
                {curriculum.sections?.map((sectionName, sectionIndex) => (
                  <div key={sectionName} className="leftbar-section">
                    {/* Section */}
                    {showSections && (
                      <div className="section-header">
                        <span>{sectionName}</span>
                      </div>
                    )}

                    {/* Chapter */}
                    {curriculum.chapters[sectionIndex]?.map(
                      (chapterName, chapterIndex) => {
                        // Generate unit key
                        const unitKey = `${sectionIndex}-${chapterIndex}`;
                        const unitsInChapter = curriculum.units[unitKey] || [];

                        return (
                          <div
                            key={`${sectionIndex}-${chapterName}`}
                            className="leftbar-chapter"
                          >
                            {showChapters && (
                              <div className="chapter-header">
                                <span>{chapterName}</span>
                              </div>
                            )}

                            {/* Unit */}
                            {unitsInChapter.map((unit) => (
                              <div key={unit.unitId} className="leftbar-unit">
                                <Droppable droppableId={unit.unitId}>
                                  {(provided, snapshot) => (
                                    <div className="unit-container">
                                      <div
                                        className={`leftbar-unit-header ${
                                          snapshot.isDraggingOver
                                            ? "drag-over"
                                            : ""
                                        }`}
                                        onClick={() => toggleUnit(unit.unitId)}
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            width: "100%",
                                          }}
                                        >
                                          <span className="leftbar-unit-name">
                                            {unit.unitName}
                                          </span>
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              gap: "4px",
                                            }}
                                          >
                                            <button
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                e.preventDefault();

                                                const currentSubunits =
                                                  curriculum.subunits[
                                                    unit.unitId
                                                  ] || [];

                                                addNewSubunit(unit.unitId);

                                                setExpandedUnits((prev) => ({
                                                  ...prev,
                                                  [unit.unitId]: true,
                                                }));
                                              }}
                                              style={{
                                                border: "none",
                                                background: "none",
                                                padding: "2px",
                                                cursor: "pointer",
                                                display: "flex",
                                                alignItems: "center",
                                              }}
                                            >
                                              <img
                                                src="/toolbaricons/inuse/add.svg"
                                                alt="Add subunit"
                                                style={{
                                                  width: "14px",
                                                  height: "14px",
                                                  filter: "invert(1)",
                                                }}
                                              />
                                            </button>
                                            <img
                                              src={
                                                expandedUnits[unit.unitId]
                                                  ? arrowDown
                                                  : arrowRight
                                              }
                                              alt="toggle"
                                              className="leftbar-unit-toggle-icon"
                                            />
                                          </div>
                                        </div>
                                      </div>

                                      <div
                                        className={`subunit-list-wrapper ${
                                          !expandedUnits[unit.unitId]
                                            ? "collapsed"
                                            : ""
                                        } ${
                                          expandedHeights[unit.unitId]
                                            ? "expanded"
                                            : ""
                                        }`}
                                      >
                                        <div
                                          className="subunit-list"
                                          ref={provided.innerRef}
                                          {...provided.droppableProps}
                                        >
                                          {curriculum.subunits[
                                            unit.unitId
                                          ]?.map((subunit, index) => {
                                            return (
                                              <Draggable
                                                key={subunit.id}
                                                draggableId={subunit.id}
                                                index={index}
                                              >
                                                {(provided) => (
                                                  <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    className={`leftbar-subunit ${
                                                      currentUnitId ===
                                                        unit.unitId &&
                                                      subunitId === subunit.id
                                                        ? "selected"
                                                        : ""
                                                    }`}
                                                    style={{
                                                      ...provided.draggableProps
                                                        .style,
                                                      display: expandedUnits[
                                                        unit.unitId
                                                      ]
                                                        ? "block"
                                                        : "none",
                                                      cursor: "default",
                                                      minHeight: "24px",
                                                    }}
                                                    onClick={() =>
                                                      handleSubunitClick(
                                                        unit,
                                                        subunit
                                                      )
                                                    }
                                                    onContextMenu={(e) =>
                                                      handleContextMenu(
                                                        e,
                                                        unit,
                                                        subunit
                                                      )
                                                    }
                                                    onDoubleClick={(e) => {
                                                      e.stopPropagation();
                                                      setEditingSubunit(
                                                        subunit.id
                                                      );
                                                    }}
                                                  >
                                                    {editingSubunit ===
                                                    subunit.id ? (
                                                      <input
                                                        type="text"
                                                        value={subunit.name}
                                                        onClick={(e) =>
                                                          e.stopPropagation()
                                                        }
                                                        onChange={(e) => {
                                                          handleSubunitNameChange(
                                                            unit.unitId,
                                                            subunit.id,
                                                            e.target.value
                                                          );
                                                        }}
                                                        onBlur={() => {
                                                          if (
                                                            subunit.name.trim() ===
                                                            ""
                                                          ) {
                                                            handleSubunitNameChange(
                                                              unit.unitId,
                                                              subunit.id,
                                                              subunit.name ||
                                                                "New Subunit"
                                                            );
                                                          }
                                                          setEditingSubunit(
                                                            null
                                                          );
                                                        }}
                                                        onKeyDown={(e) => {
                                                          if (
                                                            e.key === "Enter"
                                                          ) {
                                                            if (
                                                              subunit.name.trim() ===
                                                              ""
                                                            ) {
                                                              handleSubunitNameChange(
                                                                unit.unitId,
                                                                subunit.id,
                                                                subunit.name ||
                                                                  "New Subunit"
                                                              );
                                                            }
                                                            setEditingSubunit(
                                                              null
                                                            );
                                                          }
                                                        }}
                                                        autoFocus
                                                      />
                                                    ) : (
                                                      <span>
                                                        {subunit.name}
                                                      </span>
                                                    )}
                                                  </div>
                                                )}
                                              </Draggable>
                                            );
                                          })}
                                          {provided.placeholder}
                                          {expandedUnits[unit.unitId] &&
                                            curriculum.subunits[unit.unitId]
                                              ?.length > 6 && (
                                              <button
                                                className="expand-toggle-button"
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  toggleExpandHeight(
                                                    unit.unitId
                                                  );
                                                }}
                                              >
                                                <img
                                                  src={
                                                    expandedHeights[unit.unitId]
                                                      ? arrowRight
                                                      : arrowDown
                                                  }
                                                  alt={
                                                    expandedHeights[unit.unitId]
                                                      ? "접기"
                                                      : "펼치기"
                                                  }
                                                  className="expand-toggle-icon"
                                                />
                                              </button>
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </Droppable>
                              </div>
                            ))}
                          </div>
                        );
                      }
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        {contextMenu.visible && (
          <ContextMenu
            x={contextMenu.x}
            y={contextMenu.y}
            onClose={handleCloseContextMenu}
            menuItems={[
              {
                label: isDeletingSubunit ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <LoadingSpinner size="small" />
                    <span>Deleting...</span>
                  </div>
                ) : (
                  "Delete Subunit"
                ),
                onClick: handleDeleteSubunit,
                disabled: isDeletingSubunit,
              },
            ]}
          />
        )}
      </DragDropContext>
    );
  }
);

export default LeftBar;
